"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileMeta = void 0;
var zod_1 = require("zod");
var FileMeta;
(function (FileMeta) {
    FileMeta.FileTagSchema = zod_1.z.union([
        zod_1.z.object({
            type: zod_1.z.enum(["checklistAnswer"]),
            reference: zod_1.z.string(),
        }),
        zod_1.z.object({
            type: zod_1.z.literal("additionalMaterialApprovalProof"),
            reference: zod_1.z.literal(undefined).optional(),
        }),
        zod_1.z.object({
            type: zod_1.z.literal("unsuccessfulWorkOrderImage"),
            reference: zod_1.z.literal(undefined).optional(),
        }),
    ]);
    FileMeta.Schema = zod_1.z.object({
        recordId: zod_1.z.string(),
        description: zod_1.z.string().optional().nullable(),
        customName: zod_1.z.string().max(255).optional().nullable(),
        createdDate: zod_1.z.date(),
        mimeType: zod_1.z.string().optional().nullable(),
        tag: FileMeta.FileTagSchema.optional().nullable(),
    });
})(FileMeta || (FileMeta = {}));
exports.FileMeta = FileMeta;
