"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderLineItem = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderLineItem;
(function (WorkOrderLineItem) {
    /*   const Test = z.any();
    type Test = z.infer<typeof Test>; */
    var ApprovalStateEnum = centralized_zod_1.z.enum([
        "notApproved",
        "pending",
        "approved",
        "approvedAndCanceled",
        "addedBeforeApprovalRequirements",
    ]);
    /*
      "Labor" would be a better name for "workingHour"
    */
    WorkOrderLineItem.TypeEnum = centralized_zod_1.z.enum(["material", "workingHour"]);
    /*
      A better name for "reduction" would probably be "deduction" or "taxDeduction",
      although it's not worth refactoring at this time.
    */
    WorkOrderLineItem.CustomerPriceDetailsSchema = centralized_zod_1.z.union([
        centralized_zod_1.z.object({
            hasCustomerPrice: centralized_zod_1.z.literal(true),
            unitPriceExcludingVatWithoutReduction: centralized_zod_1.z.number(),
            unitPriceIncludingVatWithoutReduction: centralized_zod_1.z.number(),
            validForTaxReduction: centralized_zod_1.z.boolean(),
            unitPriceIncludingVatWithReduction: centralized_zod_1.z.number(),
            taxReductionType: centralized_zod_1.z.enum(["rot", "green", "rut"]).nullable(),
            vatPercentageDecimal: centralized_zod_1.z.number(),
            vatAmount: centralized_zod_1.z.number(),
        }),
        centralized_zod_1.z.object({
            hasCustomerPrice: centralized_zod_1.z.literal(false),
            unitPriceExcludingVatWithoutReduction: centralized_zod_1.z.literal(undefined).optional(),
            unitPriceIncludingVatWithoutReduction: centralized_zod_1.z.literal(undefined).optional(),
            validForTaxReduction: centralized_zod_1.z.literal(undefined).optional(),
            unitPriceIncludingVatWithReduction: centralized_zod_1.z.literal(undefined).optional(),
            taxReductionType: centralized_zod_1.z.literal(undefined).optional(),
            vatPercentageDecimal: centralized_zod_1.z.literal(undefined).optional(),
            vatAmount: centralized_zod_1.z.literal(undefined).optional(),
        }),
    ]);
    WorkOrderLineItem.HandymanCompensationDetails = centralized_zod_1.z.union([
        centralized_zod_1.z.object({
            showCompensation: centralized_zod_1.z.literal(true),
            compensationPerUnit: centralized_zod_1.z.number(),
        }),
        centralized_zod_1.z.object({
            showCompensation: centralized_zod_1.z.literal(false),
            compensationPerUnit: centralized_zod_1.z.literal(undefined).optional(),
        }),
    ]);
    WorkOrderLineItem.PermissionsSchema = centralized_zod_1.z.object({
        canBeRemoved: centralized_zod_1.z.boolean(),
        canBeCanceled: centralized_zod_1.z.boolean(),
        canEditQuantity: centralized_zod_1.z.boolean(),
    });
    WorkOrderLineItem.CustomerBaseSchema = centralized_zod_1.z.object({
        createdFor: centralized_zod_1.z.literal("customer"),
        id: centralized_zod_1.z.string(),
        name: centralized_zod_1.z.string(),
        quantity: centralized_zod_1.z.number().refine(function (val) { return val > 0; }),
        createdDate: centralized_zod_1.z.date(),
        lastModifiedDate: centralized_zod_1.z.date(),
        unit: centralized_zod_1.z.string(),
        type: WorkOrderLineItem.TypeEnum,
        approvalState: ApprovalStateEnum,
        isInactive: centralized_zod_1.z.boolean(),
        customerPriceDetails: WorkOrderLineItem.CustomerPriceDetailsSchema,
        /*
          Previously called "addedByHandyman". Comments on why it was called this
          and why we've now changed the name can be found in
          V2_WorkOrderLineItemQueryRepository.ts
        */
        isAdditionalLineItem: centralized_zod_1.z.boolean(),
    });
    WorkOrderLineItem.HandymanBaseSchema = WorkOrderLineItem.CustomerBaseSchema.extend({
        createdFor: centralized_zod_1.z.literal("handyman"),
        compensationDetails: WorkOrderLineItem.HandymanCompensationDetails,
        linkedMaterialApprovalImages: centralized_zod_1.z
            .object({
            id: centralized_zod_1.z.string(),
            url: centralized_zod_1.z.string(),
        })
            .array(),
        permissions: WorkOrderLineItem.PermissionsSchema,
        /*
          This can be used to check if the line item was actually added by a handyman,
          like the above field which was previously called "addedByHandyman" was used
          for previously.
    
          As the name suggests, it includes the id of the handyman if it exists, which
          is needed when evaluating if a line item should be merged in
          HandymanWorkOrderAddLineItemMutation.ts
        */
        handymanId: centralized_zod_1.z.string().nullable(),
        /*
          This is used to match the submitted entry with the existing line items when adding new line items.
        */
        pricebookEntryId: centralized_zod_1.z.string(),
    });
    var CustomProductExtension = centralized_zod_1.z.object({
        isCustom: centralized_zod_1.z.literal(true),
        productId: centralized_zod_1.z.literal(undefined).optional(),
    });
    var NonCustomProductExtension = centralized_zod_1.z.object({
        isCustom: centralized_zod_1.z.literal(false),
        productId: centralized_zod_1.z.string(),
    });
    WorkOrderLineItem.HandymanSchema = centralized_zod_1.z.union([
        WorkOrderLineItem.HandymanBaseSchema.merge(NonCustomProductExtension),
        WorkOrderLineItem.HandymanBaseSchema.merge(CustomProductExtension),
    ]);
    WorkOrderLineItem.CustomerSchema = centralized_zod_1.z.union([
        WorkOrderLineItem.CustomerBaseSchema.merge(NonCustomProductExtension),
        WorkOrderLineItem.CustomerBaseSchema.merge(CustomProductExtension),
    ]);
    /*
      This was refactored to include the source property in the context of
      refactoring the code for adding and modifying line items.
  
      The source allows us to know where to look to validate that this line
      item can actually be added. I.e. if the client specifies that this is
      a quickAddLabor, we only need to fetch the linked "extraHour" and validate
      that the id matches. If the client specifies that this is a freeSearch,
      we need to validate that the pricebookId matches the pricebook linked to
      the work order, and so on... But we only need to validate one of these
      at a time, reducing api requests.
    */
    WorkOrderLineItem.NewEntrySchema = centralized_zod_1.z.union([
        centralized_zod_1.z.object({
            productId: centralized_zod_1.z.string(),
            quantity: centralized_zod_1.z.number(),
            workOrderId: centralized_zod_1.z.string(),
            source: centralized_zod_1.z.enum(["quickAddMaterial", "freeSearch"]),
        }),
        centralized_zod_1.z.object({
            /*
              A better term might me "manual", but the "custom" term is used
              in other places so I'll leave this for consistency.
            */
            source: centralized_zod_1.z.literal("custom"),
            subject: centralized_zod_1.z.string().min(1),
            unitPrice: centralized_zod_1.z.number(),
            quantity: centralized_zod_1.z.number(),
            workOrderId: centralized_zod_1.z.string(),
        }),
    ]);
})(WorkOrderLineItem || (WorkOrderLineItem = {}));
exports.WorkOrderLineItem = WorkOrderLineItem;
