import { useContext } from "react";
import ClientUtils from "./../../../../../../utils/ClientUtils";

import { AppLoader } from "../../../../../common/loaders/AppLoader";
import HandymanLineItemContext from "../../../line-items/HandymanLineItemContext";
import HandymanQuickAddMaterialForm from "../components/HandymanQuickAddMaterialForm";

interface Props {
  className?: string;
}

const ExtraMaterialQuickAddSection = (props: Props) => {
  const ctx = useContext(HandymanLineItemContext);

  const data = [
    ...ctx.getMaterialQuickAddLineItems(),
    ...ctx.getWorkingHourQuickAddLineItems(),
  ];

  const isError = ctx.quickAddRes.isError;
  const isLoading = ctx.quickAddRes.isLoading;
  const moreThanFour = data.length > 4;

  return (
    <section
      className={ClientUtils.twClassNames(
        "flex flex-col gap-2",
        props.className
      )}
      id="materialQuickAddSection"
    >
      <header className="border-b pb-1">
        <h3 className="text-sm">Snabbval</h3>
      </header>
      <main
        className={ClientUtils.twClassNames(
          "flex flex-col gap-2 rounded ",
          moreThanFour &&
            "h-64 overflow-y-auto rounded p-4 shadow-inner shadow-gray-400"
        )}
      >
        {isLoading && <AppLoader />}
        {isError && (
          <p className="p-2 text-red-600">
            Det gick inte att hämta snabbvalsprodukter just nu
          </p>
        )}
        {!!data.length &&
          data
            .sort((a, b) => {
              return a.name.localeCompare(b.name);
            })
            .map((material) => {
              return (
                <HandymanQuickAddMaterialForm
                  key={material.id + "quickAddForm"}
                  disabled={isLoading}
                  type={
                    material.type === "workingHour" ? "workingHour" : "material"
                  }
                  material={material}
                  defaultQuantity={1}
                  step={material.type === "workingHour" ? 0.5 : 1}
                  min={1}
                  inputId={material.id + "QuantityInput"}
                />
              );
            })}
      </main>
    </section>
  );
};

export default ExtraMaterialQuickAddSection;
