import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "@ipis/centralized-zod";
import { motion } from "framer-motion";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import GoBackButton from "../../../components/GoBackButton";
import { AppButton } from "../../../components/common/buttons/AppButton";
import { AppFormSelect } from "../../../components/common/select/AppFormSelect";
import { AppFormTextArea } from "../../../components/common/text-areas/AppFormTextArea";
import ImageUploadComponent from "../../../components/files/ImageUploadComponent";
import { HandymanWorkOrderFileContext } from "../../../components/work-order/files/DecoupledWorkOrderFileContextProvider";
import { FileUploadNewFileSchema } from "../../../hooks/file-upload-hooks";
import { useApiClients } from "../../../hooks/use-api-clients";
import ClientUtils from "./../../../utils/ClientUtils";
import HandymanWorkOrderRouteContext from "./contexts/HandymanWorkOrderRouteContext";
import { useLoading } from "@ipis/client-essentials";

const FormSchema = z.union([
  z.object({
    workOrderId: z.string(),
    reason: z.literal("other"),
    comment: z.string().min(1).max(255),
    images: FileUploadNewFileSchema.array().optional(),
  }),
  z.object({
    workOrderId: z.string(),
    // Regex for everything except 'other'
    reason: z.string().regex(/^(?!other$).+$/),
    comment: z.string().max(255).optional(),
    images: FileUploadNewFileSchema.array().optional(),
  }),
]);

type FormValues = z.infer<typeof FormSchema>;

interface Props {
  className?: string;
  onClose(): any;
}

const WorkOrderFailedWorkOrderModal = (props: Props) => {
  const { orderRes, order } = useContext(HandymanWorkOrderRouteContext);
  const fileCtx = useContext(HandymanWorkOrderFileContext);
  const form = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      workOrderId: order.orderId,
    },
    resolver: zodResolver(FormSchema),
  });
  const { handymanWorkOrderApiClient } = useApiClients();
  const loader = useLoading();

  form.watch();

  async function onSubmit(values: FormValues) {
    loader.loadWhilePromise(_onSubmit(values));
  }

  async function _onSubmit(values: FormValues) {
    try {
      await orderRes.mutate({
        callback: async () => {
          const images = values.images;
          const hasImages = !!images && images.length > 0;

          if (hasImages) {
            const imageRes =
              await handymanWorkOrderApiClient.uploadUnsuccessfulWorkOrderImages(
                {
                  workOrderId: values.workOrderId,
                  images,
                }
              );

            if (!imageRes.success) {
              const title = (() => {
                switch (imageRes.reason) {
                  case "FAILED_TO_GENERATE_PRESIGNED_URL":
                  case "FAILED_TO_UPLOAD_ALL_FILES":
                    return "Bilderna kunde inte laddas upp";
                  case "FAILED_TO_UPLOAD_SOME_FILES":
                    return "Vissa bilder kunde inte laddas upp";
                }
              })();

              const proceeed = await window.modal.confirm({
                title,
                prompt: "Vill du markera arbetet som ej utfört ändå?",
                yesLabel: "Markera arbetet som ej utfört",
                noLabel: "Avbryt",
              });

              if (!proceeed) {
                return;
              }
            }
          }

          await handymanWorkOrderApiClient.reportAsUnsuccessful(values);
          props.onClose();
        },
        optimisticUpdate: {
          ...order,
          isFinished: true,
          //apiStatus: "Klarmarkerad av hantverkare",
        },

        onSettled: () => {
          fileCtx.workOrderFileRes.query.refetch();
        },
      });
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte markera arbetet som misslyckat just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: loader.isLoading ? 0.5 : 1 }}
      exit={{ opacity: 0 }}
      className={ClientUtils.twClassNames(
        props.className,
        "flex h-full w-full flex-col bg-white/90 p-4 backdrop-blur md:pt-32",
        loader.isLoading && "pointer-events-none"
      )}
    >
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="mx-auto flex h-full w-full max-w-screen-lg flex-col gap-2 overflow-auto"
      >
        <header className="flex w-full justify-between gap-2 py-2">
          <h2 className="text-xl">Arbetet gick ej att utföra</h2>
        </header>
        <section className="flex grow flex-col gap-4 overflow-auto p-2">
          <AppFormSelect
            register={form.register}
            name="reason"
            label="Varför gick inte arbetet att utföra?"
            options={[
              ...order.workOrderStatus.unsuccessfulOptions.map((opt) => ({
                value: opt,
                label: opt,
              })),
              {
                label: "Annan anledning",
                value: "other",
              },
            ]}
          />

          <motion.fieldset
            className="flex w-full flex-col gap-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <AppFormTextArea
              register={form.register}
              name="comment"
              label={
                form.watch("reason") === "other"
                  ? "Ange anledning"
                  : "Eventuell kommentar"
              }
              htmlAttributes={{
                //required: form.watch("reason") === "other",
                rows: 3,
              }}
            />
          </motion.fieldset>
          <Controller
            control={form.control}
            name="images"
            render={({ field }) => (
              <ImageUploadComponent
                id="failed-work-order-images"
                prompt="Bilder på omständigheterna för bomkörning (om relevant)"
                fileUploadProps={{
                  currentFiles: field.value ?? [],
                  onChange: (files) => field.onChange(files),
                }}
              />
            )}
          />
        </section>
        <footer className="flex flex-col items-end gap-2">
          <AppButton
            requiresNetworkConnection
            type="submit"
            contentClassName="w-72"
            disabled={!form.formState.isValid}
          >
            Bekräfta ej utfört arbete
          </AppButton>
          <GoBackButton
            onClick={props.onClose}
            appButtonProps={{
              className: "w-72",
            }}
          />
        </footer>
      </form>
    </motion.section>
  );
};

export default WorkOrderFailedWorkOrderModal;
