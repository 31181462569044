import ClientUtils from "../../../utils/ClientUtils";
interface Props {
  className?: string;
  white?: boolean;
}

const FlyttgarantiSeLogo = (props: Props) => {
  if (props.white) {
    return (
      <img
        className={ClientUtils.classNames("rounded", props.className)}
        src={"/logos/flyttgaranti-se-white.svg"}
        data-testid="flyttgaranti-se-logo"
        alt="Logga för Flyttgaranti.se"
      />
    );
  }
  return (
    <img
      className={ClientUtils.classNames("rounded", props.className)}
      src={"/logos/flyttgaranti-se.svg"}
      data-testid="flyttgaranti-se-logo"
      alt="Logga för Flyttgaranti.se"
    />
  );
};

export default FlyttgarantiSeLogo;
