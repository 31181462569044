import {
  FormElementAnswer,
  IpisFormElementIsolatedAnswer,
} from "@eljouren/domain";
import { IpisFileV2 } from "@eljouren/file-schemas";
import IClientWorkOrderFileRepoV2 from "../../repos/interfaces/IClientWorkOrderFileRepoV2";
import {
  ClientSideSuccessfulGetPreparationFormOutput,
  TrpcSuccessfulGetPreparationFormOutput,
} from "./preparation-form-api-client-types";

interface Props {
  fileServerApiClient: IClientWorkOrderFileRepoV2;
}

/* 
	Uses the presigned URLs returned from the server to fetch the images and attach them to the answers.
	Isolated in this class for easier testing and to be able to reuse the same logic in different
	api clients.
*/
export default class PreparationFormFileAttacher {
  constructor(private readonly props: Props) {}

  /* 
	The returntype is to alert the caller of the error without throwing,
	so that the caller can handle the error by notifying the backend that the
	image fetching step failed.
  */
  public async attach(input: TrpcSuccessfulGetPreparationFormOutput): Promise<
    | {
        success: true;
        output: ClientSideSuccessfulGetPreparationFormOutput;
      }
    | {
        success: false;
        error: unknown;
        outputWithoutFiles: ClientSideSuccessfulGetPreparationFormOutput;
      }
  > {
    try {
      const presigned = input.presigned;
      let files: IpisFileV2.Type[] = [];

      if (presigned) {
        const res =
          await this.props.fileServerApiClient.getFilesWithPresignedUrlSafe({
            url: presigned.url,
          });

        if (!res.success) {
          return {
            success: false,
            /* 
              Will give a nested error in the logs, but doesn't really matter.
            */
            error: {
              type: res.errorType,
              error: res.error,
              status: res.status,
            },
            outputWithoutFiles: this.buildOutput({
              input,
              files: [],
            }),
          };
        }

        files = res.files;
      }

      return {
        success: true,
        output: this.buildOutput({
          input,
          files: files,
        }),
      };
    } catch (error) {
      return {
        success: false,
        error,
        outputWithoutFiles: this.buildOutput({
          input,
          files: [],
        }),
      };
    }
  }

  /* 
    Extract this logic, so that we can return a transformed output even if the
    image fetching step fails (with no files attached),
  */
  private buildOutput(args: {
    input: TrpcSuccessfulGetPreparationFormOutput;
    files: IpisFileV2.Type[];
  }): ClientSideSuccessfulGetPreparationFormOutput {
    return {
      ...args.input,
      answers: this.buildAnswers({
        serverSideAnswers: args.input.answers,
        files: args.files,
      }),
    };
  }

  private buildAnswers(args: {
    serverSideAnswers: IpisFormElementIsolatedAnswer.ServerSideType[];
    files: IpisFileV2.Type[];
  }): IpisFormElementIsolatedAnswer.ClientSideType[] {
    const { serverSideAnswers, files } = args;
    const clientSide: IpisFormElementIsolatedAnswer.ClientSideType[] =
      serverSideAnswers.map((a) => {
        /* 
  If the answer isn't of type image-group, there are no images 
  to attach.
*/
        if (a.type !== "image-group") {
          return a;
        }

        const imagesRelatedToCurrentAnswer = files
          .filter((i) => a.id === i.recordId)
          .map((el) => {
            const img: FormElementAnswer.PostUploadImageType = {
              state: "postupload",
              ...el,
            };
            return img;
          });

        const answer: IpisFormElementIsolatedAnswer.ClientSideImageGroupType = {
          ...a,
          answer: {
            imageUploadComment: a.answer.imageUploadComment,
            images: imagesRelatedToCurrentAnswer,
          },
        };

        return answer;
      });

    return clientSide;
  }
}
