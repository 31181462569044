import {
  WorkOrderLineItem,
  WorkOrderQuickAddLineItem,
} from "@eljouren/domain/build";
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useRef, useState } from "react";
import { GlobalContext } from "../../../../../../top-level-contexts";
import AppTextButton from "../../../../../common/buttons/AppTextButton";
import AppPlusIcon from "../../../../../icons/AppPlusIcon";
import HandymanLineItemContext from "../../../line-items/HandymanLineItemContext";
import ClientUtils from "./../../../../../../utils/ClientUtils";

export type QuickAddFormValues = {
  quantity: number;
};

export type QuickAddSubmitValues = {
  optimisticLineItem: WorkOrderLineItem.HandymanType;
  values: QuickAddFormValues;
  material: WorkOrderQuickAddLineItem.Type;
};

interface Props {
  className?: string;
  material: WorkOrderQuickAddLineItem.Type;
  disabled?: boolean;
  defaultQuantity?: number;
  type: "material" | "workingHour";
  step: number;
  min: number;
  inputId: string;
}

const HandymanQuickAddMaterialForm = (props: Props) => {
  const ctx = useContext(HandymanLineItemContext);
  const { isOnline } = useContext(GlobalContext);

  const unit = props.type === "material" ? "st" : "h";

  const [timeout, setTimeoutState] = useState<NodeJS.Timeout | null>(null);
  const quantityRef = useRef<number>(0);

  async function onSubmit() {
    const clear = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };

    const clearAndReset = () => {
      clear();
      setTimeoutState(null);
      quantityRef.current = 0;
    };

    clear();
    /* const res = await window.modal.confirm({
      title: "Kabel ingår redan",
      prompt:
        "15 meter kabel ingår redan i arbetsordern. Vill du lägga till mer?",
      yesLabel: "Lägg till",
      noLabel: "Avbryt",
    });

    if (!res) {
      return;
    } */

    quantityRef.current += props.step;

    setTimeoutState(
      setTimeout(() => {
        ctx.onQuickAddProduct({
          quantity: quantityRef.current,
          lineItem: props.material,
          type: props.type,
        });

        clearAndReset();
      }, 1500)
    );
  }

  let name = props.material.name;
  /* if (process.env.NODE_ENV === "development") {
    name = "SOMETHINGREALLYLONGTOTESTWITH ";
  } */

  return (
    <form
      className={ClientUtils.twClassNames(
        props.className,
        "grid grid-cols-[minmax(0,1fr),min-content] gap-2"
      )}
    >
      <h3 className="w-full items-center break-words text-sm font-normal">
        {name}
      </h3>
      <fieldset
        data-quick-add-type={props.type}
        className={ClientUtils.twClassNames(
          "flex items-center justify-end gap-2"
        )}
      >
        <AppTextButton
          disabled={props.disabled || !isOnline}
          type="submit"
          onClick={onSubmit}
          className="relative rounded-full border border-black p-1"
          aria-label={
            props.type === "workingHour"
              ? "Lägg till extratimmar"
              : "Lägg till material"
          }
        >
          <AppPlusIcon size={24} />
          <AnimatePresence>
            {quantityRef.current > 0 && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute right-full top-0 mr-2 flex h-full items-center rounded bg-slate-200 px-4 font-normal"
              >
                {quantityRef.current}
                {unit}
              </motion.span>
            )}
          </AnimatePresence>
        </AppTextButton>
      </fieldset>
    </form>
  );
};

export default HandymanQuickAddMaterialForm;
