import ClientUtils from "../../../utils/ClientUtils";
interface Props {
  className?: string;
}

const OffertaLogo = (props: Props) => {
  return (
    <img
      className={ClientUtils.twClassNames("rounded", props.className)}
      src={"/logos/offerta-logo.png"}
      data-testid="offerta-logo"
      alt="Logga för Offerta Direkt"
    />
  );
};

export default OffertaLogo;
