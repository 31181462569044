import {
  Checklist,
  ChecklistAnswers,
  WorkOrder,
  WorkOrderConfirmation,
  WorkOrderEditableFields,
  WorkOrderFailedReport,
  WorkOrderFinishedReport,
  WorkOrderLineItem,
  WorkOrderProduct,
  WorkOrderQuickAddLineItem,
} from "@eljouren/domain";
import { UUID } from "@eljouren/utils";
import { CustomFileInputFile } from "../../components/files/FileInputButton";
import trpcClient from "../../trpc-setup";
import MaybeTrpcError from "../../utils/errors/MaybeTrpcError";
import { ClientAuthRepo } from "./ClientAuthRepo";
import { CheckInOutParams } from "./interfaces/IWorkOrderRepo";

export class WorkOrderRepo {
  private customerOrderFetchedCallbacks: Record<
    string,
    (order: WorkOrder.Type) => void
  > = {};

  /* 
    Temporarily take the authRepo as an argument,
    until the authenticateCustomerWithWorkOrder function is 
    implemented correctly.
  */
  constructor(private authRepo: ClientAuthRepo) {}

  onWorkOrderFetched(callback: (order: WorkOrder.Type) => void): () => void {
    const id = UUID.generate().value;

    this.customerOrderFetchedCallbacks[id] = callback;

    return () => {
      delete this.customerOrderFetchedCallbacks[id];
    };
  }

  private notifyWorkOrderFetched(order: WorkOrder.Type) {
    Object.values(this.customerOrderFetchedCallbacks).forEach((callback) =>
      callback(order)
    );
  }

  async getHolidaysForYears(years: number[]): Promise<{
    [utcString: string]: {
      swedishName: string;
      date: Date;
    };
  }> {
    const res = await trpcClient.workOrder.getHolidaysForYears.query({
      years,
    });
    return res.holidays;
  }

  /* 
    This is to authenticate and fetch the work order in a "single" request.
    Currently, we'll do it in two steps but hide it using this method.

    Later, we should refactory the customer auth to return a work order as well.

    Not sure if this is best to have in the AuthRepo or in this one.
  */
  async authenticateCustomerWithWorkOrder(args: {
    guid: string;
  }): Promise<WorkOrder.CustomerType> {
    const res = await this.authRepo.customerAuthentication(args.guid);
    if (res === 404) {
      throw new Error("Auth failed");
    }
    return this.getByCustomerGuid();
  }

  async getByCustomerGuid(): Promise<WorkOrder.CustomerType> {
    try {
      const res = await trpcClient.workOrder.getCustomerWorkOrder.query();
      this.notifyWorkOrderFetched(res);
      return res;
    } catch (er) {
      throw new MaybeTrpcError(er);
    }
  }
  async getByStaffGuid(): Promise<WorkOrder.CustomerType> {
    const res = await trpcClient.workOrder.getStaffWorkOrder.query();
    this.notifyWorkOrderFetched(res);
    return res;
  }

  async getHandymanWorkOrder(
    guid: string
  ): Promise<WorkOrder.HandymanWithPermissionsType> {
    const res = await trpcClient.workOrder.getHandymanWorkOrder.query({
      guid,
    });

    this.notifyWorkOrderFetched(res);
    return res;
  }

  async getForHandymanBetween(args: {
    handymanId: string;
    interval: { start: Date; end: Date };
  }): Promise<WorkOrder.HandymanWithPermissionsType[]> {
    const res =
      await trpcClient.workOrder.getHandymanWorkOrdersInInterval.query({
        handymanId: args.handymanId,
        interval: {
          start: args.interval.start.toISOString(),
          end: args.interval.end.toISOString(),
        },
      });
    return res;
  }

  async reset(baseValues: { orderId: string }): Promise<boolean> {
    const base =
      process.env.REACT_APP_PROXY_URL +
      "/test/resetWorkOrder/" +
      baseValues.orderId;
    await fetch(base, { method: "PATCH", credentials: "include" });
    return true;
  }

  async edit(
    args: { workOrderId: string } & WorkOrderEditableFields.Type
  ): Promise<void> {
    await trpcClient.workOrder.edit.mutate(args);
  }

  async confirm(args: {
    workOrderId: string;
    values: WorkOrderConfirmation.Type;
  }): Promise<void> {
    await trpcClient.workOrder.confirm.mutate(args.values);
  }

  async checkIn(params: CheckInOutParams): Promise<void> {
    return this.checkInOut(params, "in");
  }
  async checkOut(params: CheckInOutParams): Promise<void> {
    return this.checkInOut(params, "out");
  }
  async checkInOut(
    params: CheckInOutParams,
    method: "in" | "out"
  ): Promise<void> {
    await trpcClient.workOrder.checkInOrOut.mutate({
      workOrderId: params.orderId,
      longitude: params.position.coords.longitude,
      latitude: params.position.coords.latitude,
      method,
    });
  }

  async searchForOrders(args: {
    query: string;
    handymanId: string;
  }): Promise<WorkOrder.HandymanWithPermissionsType[]> {
    const res = await trpcClient.workOrder.search.query(args);
    return res;
  }

  async fetchChecklist(workOrderId: string): Promise<Checklist.Type> {
    return trpcClient.workOrder.fetchChecklist.query({ workOrderId });
  }
  async reportChecklistAnswers(args: {
    workOrderId: string;
    answers: ChecklistAnswers.Type;
  }): Promise<void> {
    return trpcClient.workOrder.reportChecklistAnswers.mutate({
      workOrderId: args.workOrderId,
      answers: args.answers,
    });
  }

  private verifyOnlyImages(images: CustomFileInputFile[]): void {
    const allFilesAreImages = images.every((file) => {
      const type = file.native.type;
      if (!type) {
        /* 
          Heic images has an empty type, so we'll let the 
          server validate this
        */
        return true;
      }
      return type.startsWith("image/");
    });

    /* 
      This should also be validated on the server,
      but I will settle for client-side validation until
      the fileserver is rewritten
    */
    if (!allFilesAreImages) {
      throw new Error("All files must be images");
    }
  }

  async reportImageChecklistAnswer(args: {
    workOrderId: string;
    images: CustomFileInputFile[];
    checklistItemId: string;
    comment: string | null;
  }): Promise<void> {
    this.verifyOnlyImages(args.images);

    const promises = args.images.map((el) =>
      trpcClient.workOrder.reportImageChecklistAnswers.mutate({
        workOrderId: args.workOrderId,
        dataUrl: el.dataUrl,
        checklistItemId: args.checklistItemId,
        comment: args.comment,
      })
    );

    await Promise.all(promises);
  }

  updateChecklistComment(args: {
    workOrderId: string;
    checklistItemId: string;
    comment: string | null;
  }): Promise<void> {
    return trpcClient.workOrder.updateChecklistComment.mutate(args);
  }

  async reportAsFinished(args: WorkOrderFinishedReport.Type): Promise<void> {
    await trpcClient.workOrder.reportAsFinished.mutate(args);
  }
  async reportAsFailed(args: WorkOrderFailedReport.Type): Promise<void> {
    await trpcClient.workOrder.reportAsFailed.mutate(args);
  }

  async fetchExtraHour(args: {
    workOrderId: string;
  }): Promise<WorkOrderQuickAddLineItem.Type | undefined> {
    const res = await trpcClient.workOrderLineItem.getExtraHour.query({
      workOrderId: args.workOrderId,
    });
    return res;
  }
  async fetchQuickAddProducts(args: {
    workOrderId: string;
  }): Promise<WorkOrderQuickAddLineItem.Type[]> {
    const res = await trpcClient.workOrderLineItem.getQuickAddProducts.query({
      workOrderId: args.workOrderId,
    });
    return res;
  }
  async fetchAllQuickAddLineItems(args: {
    workOrderId: string;
  }): Promise<WorkOrderQuickAddLineItem.Type[]> {
    const res =
      await trpcClient.workOrderLineItem.getAllQuickAddLineItems.query({
        workOrderId: args.workOrderId,
      });
    return res;
  }

  async searchForMaterials(args: {
    workOrderId: string;
    query: string;
  }): Promise<WorkOrderProduct.Type[]> {
    const res = await trpcClient.workOrderLineItem.searchForMaterial.query(
      args
    );
    return res;
  }

  async reportMaterial(values: WorkOrderLineItem.NewEntryType): Promise<void> {
    await trpcClient.workOrderLineItem.reportMaterial.mutate(values);
  }

  async fetchReportedHours(args: {
    workOrderId: string;
  }): Promise<WorkOrderLineItem.HandymanType[]> {
    const res = await trpcClient.workOrderLineItem.fetchReportedHours.query(
      args
    );
    return res;
  }

  async fetchLineItems(args: {
    workOrderId: string;
  }): Promise<WorkOrderLineItem.HandymanType[]> {
    const res = await trpcClient.workOrderLineItem.fetchLineItems.query(args);
    return res;
  }

  async reportExtraHour(values: {
    workOrderId: string;
    quantity: number;
  }): Promise<void> {
    await trpcClient.workOrderLineItem.reportExtraHour.mutate(values);
  }

  async fetchReportedMaterials(args: {
    workOrderId: string;
    //workerId: string;
  }): Promise<WorkOrderLineItem.HandymanType[]> {
    const res = await trpcClient.workOrderLineItem.fetchReportedMaterials.query(
      args
    );

    const withDates: WorkOrderLineItem.HandymanType[] = res.map((el) => ({
      ...el,
      createdDate: new Date(el.createdDate),
    }));

    return withDates;
  }
  async removeLineItem(args: {
    entryId: string;
    workOrderId: string;
  }): Promise<void> {
    await trpcClient.workOrderLineItem.deleteLineItem.mutate({
      entryId: args.entryId,
      workOrderId: args.workOrderId,
    });
  }
  async removeLineItems(args: {
    entryIds: string[];
    workOrderId: string;
  }): Promise<void> {
    return trpcClient.workOrderLineItem.deleteLineItems.mutate(args);
  }

  async editLineItemQuantities(args: {
    workOrderId: string;
    lineItems: WorkOrderLineItem.HandymanType[];
  }): Promise<void> {
    const someCantEdit = args.lineItems.some(
      (el) => !el.permissions.canEditQuantity
    );
    if (someCantEdit) {
      throw new Error("Bad request - Some line items can't be edited");
    }

    await trpcClient.workOrderLineItem.editLineItemQuantities.mutate({
      workOrderId: args.workOrderId,
      lineItems: args.lineItems.map((el) => ({
        entryId: el.id,
        quantity: el.quantity,
      })),
    });
  }
}
