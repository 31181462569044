import { FormElementWithState, IpisForm } from "@eljouren/domain";
import {
  FileUploadHookFile,
  FileUploadNewFile,
} from "../../../hooks/file-upload-hooks";
import trpcClient from "../../../trpc-setup";
import IClientWorkOrderFileRepoV2 from "../../repos/interfaces/IClientWorkOrderFileRepoV2";
import {
  ApiClientSubmitPreparationFormOutput,
  CustomerGetPreparationFormOutput,
  DeleteFormInput,
  GetAllFormsOutput,
  GetAllValidFormConnectionsOutput,
  HandymanGetPreparationFormOutput,
  SaveFormInput,
  SaveFormOutput,
  SubmitPreparationFormInput,
  SubmitPreparationFormOutput,
} from "./preparation-form-api-client-types";
import PreparationFormFileAttacher from "./PreparationFormFileAttacher";

type ImageElementWithAnswer = FormElementWithState.ValidImageGroupType & {
  state: FormElementWithState.ValidImageGroupType["state"] & {
    answer: Exclude<
      FormElementWithState.ValidImageGroupType["state"]["answer"],
      undefined
    >;
  };
};

export default class PreparationFormApiClient {
  async saveForm(args: SaveFormInput): Promise<SaveFormOutput> {
    return trpcClient.formStaffRouter.saveForm.mutate(args);
  }

  async getAll(): Promise<GetAllFormsOutput> {
    return trpcClient.formStaffRouter.getAllForms.query();
  }

  async delete(args: DeleteFormInput): Promise<void> {
    return trpcClient.formStaffRouter.deleteForm.mutate(args);
  }

  async getAllValidFormConnections(): Promise<GetAllValidFormConnectionsOutput> {
    return trpcClient.formStaffRouter.getAllValidFormConnections.query();
  }

  async getPreparationFormForHandymanOrStaff(args: {
    fileRepo: IClientWorkOrderFileRepoV2;
    workOrderId: string;
  }): Promise<HandymanGetPreparationFormOutput> {
    const res =
      await trpcClient.authenticatedWorkOrderPreparationFormRouter.getPreparationForm.query(
        {
          workOrderId: args.workOrderId,
        }
      );
    if (!res.success) {
      throw new Error("Failed to get preparations form");
    }

    const fileAttacher = new PreparationFormFileAttacher({
      fileServerApiClient: args.fileRepo,
    });
    const transformedResult = await fileAttacher.attach(res);
    if (!transformedResult.success) {
      await trpcClient.authenticatedWorkOrderPreparationFormRouter.logFailedImageFetching.mutate(
        {
          workOrderId: args.workOrderId,
          outputReceivedFromServer: res,
          error: transformedResult.error,
        }
      );
      /* 
        We don't want to throw here, because we want the users to be able to see the other answers
        even if the image fetching step fails.
      */
      //throw transformedResult.error;
      return transformedResult.outputWithoutFiles;
    }
    return transformedResult.output;
  }

  async getPreparationFormForCustomer(args: {
    fileRepo: IClientWorkOrderFileRepoV2;
  }): Promise<CustomerGetPreparationFormOutput> {
    const res =
      await trpcClient.customerWorkOrderPreparationFormRouter.getPreparationForm.query();
    if (!res.success) {
      return res;
    }
    const fileAttacher = new PreparationFormFileAttacher({
      fileServerApiClient: args.fileRepo,
    });

    const transformedResult = await fileAttacher.attach(res);
    if (!transformedResult.success) {
      await trpcClient.customerWorkOrderPreparationFormRouter.logFailedImageFetching.mutate(
        {
          outputReceivedFromServer: res,
          error: transformedResult.error,
        }
      );
      throw transformedResult.error;
    }
    return transformedResult.output;
  }

  async submitPreparationForm(
    args: SubmitPreparationFormInput & {
      validatedForm: IpisForm.FullyValidatedType;
      fileServerApiClient: IClientWorkOrderFileRepoV2;
    }
  ): Promise<ApiClientSubmitPreparationFormOutput> {
    const res =
      await trpcClient.customerWorkOrderPreparationFormRouter.submitPreparationForm.mutate(
        {
          form: args.form,
          answers: args.answers,
        }
      );

    if (res.success) {
      const uploadResult = await this.uploadImages({
        ...args,
        output: res,
      });

      return {
        serverSideResult: res,
        imageUploadResult: uploadResult,
      };
    }

    return {
      serverSideResult: res,
    };
  }

  /* 
    The name of these images will be previously set to match the prompt of the question in
    ChecklistImageUploadComponent.tsx.

    This is how we link the image to the question, which is very hacky. More info in the comment
    in ChecklistImageUploadComponent.tsx.
  */
  private async uploadImages(
    args: SubmitPreparationFormInput & {
      validatedForm: IpisForm.FullyValidatedType;
      fileServerApiClient: IClientWorkOrderFileRepoV2;
      output: Extract<SubmitPreparationFormOutput, { success: true }>;
    }
  ): Promise<"success" | "partial-success" | "failed"> {
    const presignedObjects = args.output.presignedUploadUrls;

    const allElements = args.validatedForm.pages.flatMap((p) => p.elements);
    const imageElementsWithAnswers = allElements.filter(
      (el): el is ImageElementWithAnswer =>
        el.typeOfQuestion === "image-group" && el.state.answer !== undefined
    );

    /* 
      This is to notify the backend and log the errors.
    */
    const elementsWithoutAPresignedUrl: ImageElementWithAnswer[] = [];
    const elementsThatFailedToUpload: ImageElementWithAnswer[] = [];

    const promises = imageElementsWithAnswers.map((el) => {
      const url = presignedObjects[el.id]?.presignResponse.url;
      if (!url) {
        elementsWithoutAPresignedUrl.push(el);
        return Promise.resolve();
      }
      const imageMap = el.state.answer.images;

      const values: FileUploadHookFile[] = Object.values(imageMap)
        .filter((v): v is FileUploadHookFile[] => !!v && Array.isArray(v))
        .flat();
      const newValues = values.filter(
        (v): v is FileUploadNewFile => v.state === "preupload"
      );

      if (newValues.length === 0) {
        return Promise.resolve();
      }

      return args.fileServerApiClient.uploadWithPresignedUrl({
        url,
        files: newValues,
      });
    });

    const results = await Promise.allSettled(promises);
    results.forEach((result, index) => {
      if (result.status === "rejected") {
        elementsThatFailedToUpload.push(imageElementsWithAnswers[index]);
      }
    });

    const successCount = results.filter((r) => r.status === "fulfilled").length;
    if (successCount === imageElementsWithAnswers.length) {
      return "success";
    } else {
      await trpcClient.customerWorkOrderPreparationFormRouter.logFailedImageUploading.mutate(
        {
          outputReceivedFromServer: args.output,
          errors: elementsThatFailedToUpload,
          missingPresignedUrls: elementsWithoutAPresignedUrl,
        }
      );
      if (successCount > 0) {
        return "partial-success";
      }
      return "failed";
    }
  }

  async resetFormAnswers(): Promise<void> {
    if (process.env.NODE_ENV !== "development") {
      throw new Error("This method is only available in development mode");
    }
    await trpcClient.formCustomerRouter.resetAnswers.mutate();
  }
}
