import { BankIdSignResponse } from "@eljouren/bank-id-schemas";
import { MaterialReviewList } from "@eljouren/domain";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "@ipis/centralized-zod";
import { IpisButton } from "@ipis/client-essentials";
import { motion } from "framer-motion";
import { useState } from "react";
import { useForm } from "react-hook-form";
import GDPRLinkAndConfirmationCheckboxV2 from "../../../components/__v2__/form-components/GDPRConfirmationCheckboxV2";
import SlideUpModal from "../../../components/__v2__/modals/SlideUpModal";
import AppCheckmarkIcon from "../../../components/icons/AppCheckmarkIcon";
import AppChevronIcon from "../../../components/icons/AppChevronIcon";
import { useLoading } from "../../../hooks/hooks";
import { useApiClients } from "../../../hooks/use-api-clients";
import ClientUtils from "../../../utils/ClientUtils";
import BankIdSignComponentV2 from "./BankIdSignComponentV2";
import { useOrderLineReviewContext } from "./CustomerOrderLineReviewRouteV2";

const formSchema = z.object({
  gdprConfirmed: z.literal(true),
});

type FormValues = z.infer<typeof formSchema>;

function formatPrice(price: number) {
  const [wholeNumber, decimal] = price.toFixed(2).split(".");

  if (!decimal || decimal === "00") {
    return `${wholeNumber}`;
  } else {
    return `${wholeNumber},${decimal}`;
  }
}

function formatPriceWithCurrency(price: number) {
  return `${formatPrice(price)} SEK`;
}

interface Props {}

const CustomerOrderLineReviewSectionV2 = (props: Props) => {
  const ctx = useOrderLineReviewContext();
  const { workOrderLineItemApprovalRequestApiClient } = useApiClients();
  const result = ctx.listData;
  const wo = ctx.workOrder;
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  const [signResponse, setSignResponse] =
    useState<BankIdSignResponse.ClientResponseType | null>(null);
  const { isLoading: isInitiatingBankId, loadWhilePromise } = useLoading();

  const [isSuccess, setIsSuccess] = useState(false);

  function onSubmit() {
    initiate();
  }

  function onCancel() {
    setSignResponse(null);
  }

  async function initiate() {
    try {
      if (!list) {
        return;
      }
      const res = await loadWhilePromise(
        workOrderLineItemApprovalRequestApiClient.initiateBankIdSigning({
          hash: list.hash,
        })
      );
      if (res.success) {
        setSignResponse(res.bankIdResponse);
      } else if (res.reason === "OUTDATED_REQUEST") {
        ctx.listRes.query.setData({
          success: true,
          approvalRequest: res.updatedRequest,
        });
        window.modal.alert({
          typeOfAlert: "notification",
          title: "Uppdaterad lista",
          prompt:
            "Listan över orderrader att godkänna har uppdaterats sen Ni började granska den. Kontrollera att allt stämmer på nytt och godkänn sedan listan så att arbetet kan fortsätta.",
          error: null,
        });
      } else {
        throw new Error("Failed to sign list");
      }
    } catch (er) {
      window.modal.alert({
        typeOfAlert: "error",
        title: "Det gick inte att skriva under listan",
        prompt:
          "Okänt fel när underskriften skulle startas. Vänligen försök igen senare.",
        error: er,
      });
    }
  }

  function onSuccess() {
    setIsSuccess(true);
    setSignResponse(null);
  }

  if (!result.success) {
    return (
      <section className="mx-auto h-full w-full max-w-screen-sm px-4 pb-4 pt-16">
        <header className="flex flex-col gap-4">
          <h2 className="text-2xl text-dark-950">Hej {wo.contact.fullName},</h2>
          <p className="text-dark-800">
            Det gick tyvärr inte att hämta listan över orderrader att godkänna.
            Vänligen försök igen senare.
          </p>
        </header>
      </section>
    );
  }

  const hasPendingLineItems =
    result.approvalRequest.pendingLineItemSummary.lineItems.length > 0;

  if (!hasPendingLineItems) {
    return (
      <section className="mx-auto h-full w-full max-w-screen-sm px-4 pb-4 pt-16">
        <header className="flex flex-col gap-4">
          <h2 className="text-2xl text-dark-950">Hej {wo.contact.fullName},</h2>
          <p className="text-dark-800">
            Din hantverkare har inte lagt till några orderrader till din
            arbetsorder <b>#{wo.serialNumber}</b>.
          </p>
        </header>
      </section>
    );
  }

  const list = result.approvalRequest;

  return (
    <form
      className="relative mx-auto flex h-full w-full max-w-screen-sm flex-col gap-8 overflow-y-auto px-4 pb-4 pt-16"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      {!isSuccess && (
        <header className="flex flex-col gap-4">
          <h2 className="text-2xl text-dark-950">Hej {wo.contact.fullName},</h2>
          <p className="text-dark-800">
            Din hantverkare har lagt till orderrader till din arbetsorder{" "}
            <b>#{wo.serialNumber}</b>. Vänligen granska och godkänn dessa innan
            arbetet påbörjas.
          </p>
        </header>
      )}
      {isSuccess && (
        <header className="grid grid-cols-[minmax(0,1fr),auto] gap-4">
          <h2 className="text-2xl text-dark-950">Hej {wo.contact.fullName},</h2>
          <AppCheckmarkIcon
            size={"lg"}
            variant="circle"
            className="fill-success text-light-background"
          />
          <p className="col-span-2 text-dark-800">
            Din hantverkare kan nu påbörja arbetet på din arbetsorder.
          </p>
        </header>
      )}
      <SummarySection list={list} />
      {!isSuccess && (
        <>
          <footer className="flex flex-col">
            <GDPRLinkAndConfirmationCheckboxV2
              form={form}
              name="gdprConfirmed"
              useCase="orderLineReview"
            />
            <IpisButton
              type="submit"
              disabled={!form.formState.isValid || isInitiatingBankId}
              loading={isInitiatingBankId}
              label="Skriv under med BankID-appen"
            />
          </footer>
          <SlideUpModal
            fadeOutOtherContent
            preventCloseOnOutsideClick
            isOpen={!!signResponse}
            //heading="Skriv under med BankID-appen"
          >
            {signResponse && (
              <BankIdSignComponentV2
                response={signResponse}
                onCancel={onCancel}
                afterSuccessfulSign={onSuccess}
              />
            )}
          </SlideUpModal>
        </>
      )}
    </form>
  );
};

const SummarySection = (props: { list: MaterialReviewList.CustomerType }) => {
  const pending = props.list.pendingLineItemSummary;

  const discountType = props.list.taxReductionType;
  const withoutDiscount = props.list.totalPriceWithoutTaxReduction;
  const withDiscount = props.list.totalPriceWithTaxReduction;
  const hasDiscount = withoutDiscount !== withDiscount;
  const discountAmount = withoutDiscount - withDiscount;

  return (
    <section className="flex flex-col gap-4">
      <h3 className="text-lg text-dark-950">Sammanfattning av orderrader</h3>
      <div className="flex flex-col gap-4 divide-y-2 border-border-color">
        <NewOrderLines summary={pending} />
        <section className="grid grid-cols-[minmax(0,1fr),auto] gap-x-2 gap-y-4 py-4">
          <h3>Delsumma</h3>
          <span className="ml-auto">
            {formatPriceWithCurrency(pending.totalPriceWithoutTaxReduction)}
          </span>
          {hasDiscount && discountType === "green" && (
            <span>Preliminärt avdrag för grön teknik</span>
          )}
          {hasDiscount && discountType === "rot" && (
            <span>Preliminärt ROT-avdrag</span>
          )}
          {hasDiscount && (
            <span className="ml-auto text-[#70A310]">
              {formatPriceWithCurrency(discountAmount)}
            </span>
          )}
        </section>
        <section className="grid grid-cols-[minmax(0,1fr),auto] gap-x-2 gap-y-4 py-4">
          <h3 className="text-lg">Totalt</h3>
          <strong className="ml-auto text-lg">
            {formatPriceWithCurrency(pending.totalPriceWithTaxReduction)}
          </strong>
        </section>
      </div>
      <PreviousOrderLines
        summary={props.list.previouslyApprovedLineItemSummary}
      />
      <Terms list={props.list} />
    </section>
  );
};

const PreviousOrderLines = (props: {
  className?: string;
  summary: MaterialReviewList.CustomerLineItemSummaryType;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  function toggleExpand() {
    setIsExpanded(!isExpanded);
  }

  return (
    <section
      className={ClientUtils.classNames(
        "flex flex-col gap-4 rounded bg-secondary-50 p-4",
        props.className
      )}
    >
      <button
        type="button"
        className="flex items-center justify-between overflow-hidden"
        onClick={toggleExpand}
        aria-expanded={isExpanded}
        aria-controls="previous-order-lines-list"
      >
        <h4 className="text-secondary-600 text-lg">Visa tidigare orderrader</h4>
        <motion.div
          initial={false}
          animate={{ rotate: isExpanded ? 180 : 0 }}
          // remove spring effect
          transition={{
            type: "tween",
            duration: 0.15,
          }}
        >
          <AppChevronIcon direction="down" />
        </motion.div>
      </button>
      {isExpanded && (
        <ul className="flex flex-col gap-4" id="previous-order-lines-list">
          {props.summary.lineItems.map((lineItem) => {
            return <LineItemComponent key={lineItem.id} lineItem={lineItem} />;
          })}
        </ul>
      )}
    </section>
  );
};

const NewOrderLines = (props: {
  className?: string;
  summary: MaterialReviewList.CustomerLineItemSummaryType;
}) => {
  return (
    <section
      className={ClientUtils.classNames("flex flex-col gap-4", props.className)}
    >
      <h4 className="text-lg text-secondary-500">Nya orderrader</h4>
      <ul className="flex flex-col gap-4">
        {props.summary.lineItems.map((lineItem) => {
          return <LineItemComponent key={lineItem.id} lineItem={lineItem} />;
        })}
      </ul>
    </section>
  );
};

const LineItemComponent = (props: {
  lineItem: MaterialReviewList.CustomerLineItemSummaryType["lineItems"][number];
}) => {
  const l = props.lineItem;
  const name = l.name;
  const quantity = l.quantity;
  const unitPriceWithoutReduction =
    l.customerPriceDetails.unitPriceIncludingVatWithoutReduction ?? 0;
  const unitPriceWithReduction =
    l.customerPriceDetails.unitPriceIncludingVatWithReduction ?? 0;
  const hasTaxReduction = unitPriceWithoutReduction !== unitPriceWithReduction;
  const taxReductionType = l.customerPriceDetails.taxReductionType;
  const total = parseFloat((quantity * unitPriceWithoutReduction).toFixed(2));

  const isInactive = props.lineItem.isInactive;

  const discountPercentage = Math.round(
    ((unitPriceWithoutReduction - unitPriceWithReduction) /
      unitPriceWithoutReduction) *
      100
  );

  let rowCount = 2;
  if (hasTaxReduction) {
    rowCount++;
  }
  if (isInactive) {
    rowCount++;
  }

  return (
    <li className="grid grid-cols-[auto,minmax(0,1fr),auto] gap-x-2">
      <span
        className="font-bold"
        style={{
          gridRowEnd: `span ${rowCount}`,
        }}
      >
        {quantity}
        <span aria-hidden>x</span>
      </span>
      <span className="text-accent">{name}</span>
      <span
        className={ClientUtils.classNames(
          isInactive && "text-dark-300 line-through",
          !isInactive && "text-accent"
        )}
      >
        {formatPriceWithCurrency(total)}
      </span>
      <span className="text-sm text-semi-faded">
        {formatPriceWithCurrency(unitPriceWithoutReduction)}
      </span>
      {hasTaxReduction && taxReductionType === "green" && (
        <span className="col-start-2 row-start-3 text-sm text-dark-400">
          Giltig för avdrag för grön teknik
        </span>
      )}
      {hasTaxReduction && taxReductionType === "rot" && (
        <span className="col-start-2 row-start-3 text-sm text-dark-400">
          Giltig för ROT-avdrag
        </span>
      )}

      {hasTaxReduction && (
        <span className="col-start-3 row-start-3 ml-auto text-sm text-[#70A310]">
          {discountPercentage}% avdrag
        </span>
      )}
      {isInactive && (
        <p
          className="col-start-2 text-sm text-dark-400"
          style={{
            gridRow: hasTaxReduction ? "4" : "3",
          }}
        >
          Orderraden har inaktiverats och kommer inte att räknas in i totalen.
        </p>
      )}
    </li>
  );
};

const Terms = (props: { list: MaterialReviewList.Type }) => {
  return (
    <section className="flex flex-col gap-2">
      <h3 className="text-lg text-dark-950">Om orderraderna</h3>
      <ul className="list-disc pl-8 text-dark-800">
        {props.list.userReadableConfirmation.terms.map((term, i) => {
          return <li key={`${props.list.workOrderId}-term-${i}`}>{term}</li>;
        })}
      </ul>
    </section>
  );
};

export default CustomerOrderLineReviewSectionV2;
