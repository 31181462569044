import { FormInputContext, IpisButton } from "@ipis/client-essentials";
import { useContext, useRef } from "react";
import {
  FileUploadFilter,
  useFileInputAccept,
  useFileUpload,
  UseFileUploadProps,
} from "../../hooks/file-upload-hooks";
import ExtensionsAndFileSizeSpan from "../__v2__/form-components/ExtensionsAndFileSizeSpan";
import DelayedRenderImage from "../__v2__/utils/DelayedRenderImage";
import AppImageIcon from "../icons/AppImageIcon";
import AppMinusIcon from "../icons/AppMinusIcon";

interface Props<F extends FileUploadFilter = "all"> {
  className?: string;
  prompt: string;
  required?: boolean;
  id: string;
  fileUploadProps: Omit<
    UseFileUploadProps<F>,
    "ref" | "allow" | "maxFileNameLength"
  >;
}

/* 
  This is essentially a copy of the ChecklistImageUploadComponent but decoupled from the checklist.
  The ChecklistImageUploadComponent could be refactored to use this in the future.
*/
const ImageUploadComponent = <F extends FileUploadFilter>(props: Props<F>) => {
  const ctx = useContext(FormInputContext);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const accept = useFileInputAccept({
    allow: "images",
    removeHeic: true,
  });

  const fileUploadReturn = useFileUpload({
    allow: "images",
    ref: inputRef,
    maxFileNameLength: 80,
    ...props.fileUploadProps,
  });

  function triggerFileInput() {
    inputRef.current?.click();
  }

  const buttonId = props.id + "-file-input-button";

  const hasPhotos = fileUploadReturn.hasFiles;

  return (
    <section className="flex flex-col gap-2">
      <fieldset className="flex flex-col gap-1">
        {!ctx?.readOnly && (
          <input
            id={props.id + "-file-input"}
            aria-describedby={buttonId}
            ref={inputRef}
            type="file"
            className="hidden"
            accept={accept}
            required={props.required}
            multiple
          />
        )}
        <h3 className="text-lg font-normal text-dark-950">
          {props.prompt}
          {props.required && <span className="pl-2 text-base">{"*"}</span>}
        </h3>
        {!hasPhotos && !ctx?.readOnly && (
          <button
            id={buttonId}
            className={
              "flex h-40 w-full flex-col items-center justify-center border border-dashed border-gray-400 text-dark-gray/80"
            }
            onClick={() => {
              triggerFileInput();
            }}
            type="button"
          >
            <AppImageIcon size={32} />
            <span className="text-dark-900">Ladda upp bilder</span>
          </button>
        )}
        {!hasPhotos && !!ctx?.readOnly && (
          <p
            className={
              "flex h-40 w-full flex-col items-center justify-center border border-dashed border-gray-400 text-dark-gray/80"
            }
          >
            {/* <AppImageIcon size={32} /> */}
            <span className="text-dark-900">Inga bilder uppladdade</span>
          </p>
        )}
        {hasPhotos && (
          <ul className="grid min-h-[10rem] grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-2 md:grid-cols-2">
            {fileUploadReturn.currentFiles.map((file) => {
              return (
                <li key={file.guid} className="relative">
                  {file.state === "preupload" && (
                    <DelayedRenderImage
                      delayInMs={1000}
                      src={file.dataUrl}
                      alt={file.meta?.name ?? "Uppladdad bild"}
                      className="h-40 w-full object-cover"
                    />
                  )}
                  {file.state === "postupload" && (
                    <DelayedRenderImage
                      delayInMs={1000}
                      src={file.src}
                      alt={file.name}
                      className="h-40 w-full object-cover"
                    />
                  )}
                  {!ctx?.readOnly && (
                    <button
                      className="absolute right-0 top-0 rounded-bl bg-white p-1"
                      onClick={() => {
                        fileUploadReturn.removeFile(file);
                      }}
                      type="button"
                      aria-label="Ta bort bild"
                    >
                      <AppMinusIcon className="rounded-full border-2 border-black" />
                    </button>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </fieldset>
      {!ctx?.readOnly && (
        <footer className="flex h-10 flex-col">
          {!hasPhotos && (
            <ExtensionsAndFileSizeSpan
              accept={accept}
              maxSizeString={fileUploadReturn.maxSizeStr}
            />
          )}
          {hasPhotos && (
            <IpisButton
              variant="text"
              label="Lägg till fler bilder"
              onClick={() => {
                triggerFileInput();
              }}
              margin={{
                ml: "auto",
              }}
            />
          )}
        </footer>
      )}
    </section>
  );
};

export default ImageUploadComponent;
