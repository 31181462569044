import { WorkOrderLineItem } from "@eljouren/domain/build";
import { useContext } from "react";
import ClientUtils from "./../../../../../../utils/ClientUtils";

import MidDot from "../../../../../common/MidDot";
import Dropdown from "../../../../../common/dropdown/Dropdown";
import { HandymanWorkOrderFileContext } from "../../../../files/DecoupledWorkOrderFileContextProvider";
import IpisFileDataList from "../../../files/IpisFileDataList";
import LineItemApprovalStateIcon from "./LineItemApprovalStateIcon";

interface Props {
  className?: string;
  data: (WorkOrderLineItem.HandymanType | WorkOrderLineItem.CustomerType)[];
  isLoading: boolean;
  useCustomerPrice?: boolean;
}

const HandymanLineItemList = (props: Props) => {
  return (
    <>
      <ul
        className={ClientUtils.twClassNames(
          "w-full",
          props.isLoading && "cursor-wait"
        )}
      >
        {props.data.map((lineItem) => {
          const { customerPriceDetails, quantity } = lineItem;

          let compensationDetails:
            | WorkOrderLineItem.HandymanType["compensationDetails"]
            | undefined;
          if (lineItem.createdFor === "handyman") {
            compensationDetails = lineItem.compensationDetails;
          }

          let hasUnitPrice: boolean;
          if (props.useCustomerPrice) {
            hasUnitPrice = customerPriceDetails.hasCustomerPrice;
          } else {
            hasUnitPrice = !!compensationDetails?.showCompensation;
          }

          const activeUnitPrice = () => {
            const res = props.useCustomerPrice
              ? customerPriceDetails.unitPriceIncludingVatWithReduction
              : compensationDetails?.compensationPerUnit;
            return res ?? 0;
          };

          const getTotal = () => {
            return Number((activeUnitPrice() * quantity).toFixed(2));
          };

          const showLabel = !!props.useCustomerPrice;

          return (
            <li
              className={ClientUtils.classNames(
                "relative flex flex-col border-b px-1 py-2 last:border-b-0",
                lineItem.isInactive && "pointer-events-none opacity-50"
              )}
              key={lineItem.id}
              data-added-material-source={
                lineItem.isAdditionalLineItem ? "handyman" : "staff"
              }
              data-quantity={quantity}
            >
              {lineItem.isInactive && (
                <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white/40 text-sm font-bold text-black">
                  Inaktiverat
                </div>
              )}
              <header className="flex items-center gap-2">
                <LineItemApprovalStateIcon lineItem={lineItem} />
                <h3 className="text-sm font-normal">{lineItem.name} </h3>
              </header>

              <main
                className={ClientUtils.classNames(
                  "col-span-2 row-start-2 grid grid-cols-[auto,minmax(0,1fr)]"
                )}
              >
                {hasUnitPrice && (
                  <span
                    className="flex items-center text-sm italic text-dark-gray text-opacity-70"
                    data-test-id="material-price"
                  >
                    <span>
                      {quantity}
                      {lineItem.unit}
                    </span>
                    <MidDot size={20} />
                    <span>{activeUnitPrice()}:-</span>
                    {showLabel && (
                      <span className="pl-2 text-xs italic">(Kundpris)</span>
                    )}
                  </span>
                )}
                {!hasUnitPrice && (
                  <span className="row-start-2 text-sm italic text-dark-gray text-opacity-70">
                    {quantity}
                    {lineItem.unit}
                  </span>
                )}

                {hasUnitPrice && (
                  <span className="ml-auto text-sm font-semibold">
                    {getTotal()}:-
                  </span>
                )}
              </main>

              {/* {canTrash && (
                <AppTextButton
                  className="col-start-2 row-span-2 ml-auto"
                  onClick={() => onTrash(lineItem)}
                  disabled={props.isLoading}
                >
                  <AppTrashIcon size={24} />
                </AppTextButton>
              )} */}
              {/* <LineItemApprovalStateComponent
                lineItem={lineItem}
                className="col-span-2"
              /> */}
              {lineItem.createdFor === "handyman" && (
                <RelatedFiles lineItem={lineItem} className="col-span-2" />
              )}
            </li>
          );
        })}
      </ul>
      {/* 
        The calculator needs to have support for customer prices
      */}
      {/*   {canViewPrices && !props.useCustomerPrice && (
        <span className="ml-auto flex flex-col p-2 text-right text-sm">
          <span className="">
            Total ersättning för{" "}
            {props.type === "hours" ? "arbetstimmar" : "material"}
          </span>
          <span className="font-bold">
            {props.type === "material" ? calc.forMaterial : calc.forHours}:-
          </span>
        </span>
      )} */}
    </>
  );
};

const RelatedFiles = (props: {
  className?: string;
  lineItem: WorkOrderLineItem.HandymanType;
}) => {
  const fileCtx = useContext(HandymanWorkOrderFileContext);

  const relatedFiles = fileCtx.allFiles.filter((file) => {
    return !!file.relations?.find((relation) => {
      const type = relation.type;
      if (type !== "workOrderLineItem") {
        return false;
      }

      return relation.lineItemId === props.lineItem.id;
    });
  });

  if (relatedFiles.length === 0) {
    return <></>;
  }

  return (
    <Dropdown
      title="Visa relaterade filer"
      className="my-2"
      buttonClassName="bg-transparent border-b w-60 p-1"
      preventHideOnOutsideClick
    >
      <IpisFileDataList
        onDelete={fileCtx.deleteFile}
        className="p-4"
        data={relatedFiles}
        filePermissionCallback={fileCtx.permissions.forFile}
      />
    </Dropdown>
  );
};

export default HandymanLineItemList;
