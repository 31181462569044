import { IpisLayout, IpisWindowModalsProvider } from "@ipis/client-essentials";
import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router-dom";
import { useBrandLogic } from "../../hooks/brand-hooks";
import { TabContextProps } from "../common/tabs/TabContext";
import TabContextProvider from "../common/tabs/TabContextProvider";
import TabList from "../common/tabs/TabList";
import SomethingWentWrong from "../on-error/SomethingWentWrong";
import AppSidebar from "./AppSidebar";
import LayoutControlsContext from "./LayoutControlsContext";
import PageHeader, { PageHeaderProps } from "./PageHeader";

interface Props {
  children?: React.ReactNode;
}

const AppLayout = (props: Props) => {
  const location = useLocation();

  const [tabProps, setTabProps] = useState<TabContextProps<string> | false>(
    false
  );
  const [pageHeaderProps, setPageHeaderProps] = useState<
    PageHeaderProps | false
  >(false);

  useBrandLogic();

  const mostRecentPath = React.useRef(location.pathname);

  useEffect(() => {
    if (mostRecentPath.current !== location.pathname) {
      setTabProps(false);
      setPageHeaderProps(false);
    }
  }, [location.pathname]);

  const regex = /(\/test|personal|kundv2\/.+)|(forberedelser$)/;
  const matches = regex.test(location.pathname);
  if (matches) {
    return <IpisLayout {...props} />;
  }

  return (
    <LayoutControlsContext.Provider
      value={{
        setTabProps: (p) => {
          mostRecentPath.current = location.pathname;
          setTabProps(p);
        },
        setPageHeaderProps: (p) => {
          mostRecentPath.current = location.pathname;
          setPageHeaderProps(p);
        },
      }}
    >
      <ConditionalTabContext tabProps={tabProps} key="tabContext">
        <AppSidebar />
        {pageHeaderProps && <PageHeader {...pageHeaderProps} />}

        {/* 
			The main content - should always be an AppPage component
		*/}
        <ErrorBoundary
          fallbackRender={(props) => {
            return <SomethingWentWrong error={props.error} />;
          }}
        >
          <AnimatePresence exitBeforeEnter>{props.children}</AnimatePresence>
          {tabProps && (
            <TabList className="col-start-1 row-start-3 lg:row-start-2" />
          )}
        </ErrorBoundary>
        <IpisWindowModalsProvider />
      </ConditionalTabContext>
    </LayoutControlsContext.Provider>
  );
};

const ConditionalTabContext = (props: {
  children?: React.ReactNode;
  tabProps: TabContextProps<string> | false;
}) => {
  if (props.tabProps) {
    return (
      <TabContextProvider {...props.tabProps}>
        {props.children}
      </TabContextProvider>
    );
  }

  return <>{props.children}</>;
};

export default AppLayout;
