import {
  MaterialReviewList,
  WorkOrder,
  WorkOrderLineItem,
} from "@eljouren/domain";
import { motion } from "framer-motion";
import PriceCalculationComponent from "../../../components/PriceCalculationComponent";
import Logo from "../../../components/common/brand/Logo";
import ExpandablePriceListItem from "../../../components/price-components/ExpandablePriceListItem";
import ClientUtils from "../../../utils/ClientUtils";
import ReviewListOverviewFooter from "./ReviewListOverviewFooter";

interface Props {
  className?: string;
  list: MaterialReviewList.CustomerType;
  workOrder: WorkOrder.Type;
  isSigning: boolean;
  onSign: () => void;
}

const OrderLineReviewListOverview = (props: Props) => {
  const { className, list, workOrder } = props;

  return (
    <motion.section
      key="reviewList"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={ClientUtils.classNames(
        "mx-auto flex max-w-screen-sm flex-col gap-16 sm:p-2",
        className
      )}
    >
      <header className="flex flex-col py-2">
        <Logo
          onlyShowSetBrand
          withColor
          wrapperClassName="max-w-[200px] py-4 mb-4 mx-auto"
        />
        <h3>Hej, {workOrder.endCustomer.name}</h3>
        <p className="">
          Hantverkaren har lagt till orderrader för arbetsorder #
          {workOrder.serialNumber}. Vänligen granska och godkänn tilläggen innan
          arbetet påbörjas.
        </p>
      </header>
      <section className="flex flex-col gap-8">
        <h3 className="mx-auto">Sammanställning av orderrader:</h3>
        <ul className="flex flex-col gap-2">
          <ExpandablePriceListItem
            label="Nya orderrader"
            price={list.pendingLineItemSummary.totalPriceWithoutTaxReduction}
            openByDefault
            useVerbosePricing
          >
            <OverviewLineItemList
              lineItems={list.pendingLineItemSummary.lineItems}
            />
          </ExpandablePriceListItem>
          {list.previouslyApprovedLineItemSummary.lineItems.length > 0 && (
            <ExpandablePriceListItem
              label="Tidigare godkända orderrader"
              price={
                list.previouslyApprovedLineItemSummary
                  .totalPriceWithoutTaxReduction
              }
              useVerbosePricing
            >
              <OverviewLineItemList
                lineItems={[
                  ...list.previouslyApprovedLineItemSummary.lineItems,
                  ...list.inactivatedLineItemSummary.lineItems,
                ]}
              />
            </ExpandablePriceListItem>
          )}
          {!!list.taxReductionType && list.taxReductionAmount > 0 && (
            <ExpandablePriceListItem
              label={
                list.taxReductionType === "rot"
                  ? "Eventuellt ROT-avdrag"
                  : "Eventuellt avdrag för grön teknik"
              }
              price={-list.taxReductionAmount}
              useVerbosePricing
            />
          )}
          <ExpandablePriceListItem
            label="Total tilläggskostnad"
            final
            price={list.totalPriceWithTaxReduction}
            useVerbosePricing
          />

          {/*  <LineItemSummaryComponent
          summary={list.pendingLineItemSummary}
          type="pending"
        />
        {list.previousLineItemSummary.lineItems.length > 0 && (
          <LineItemSummaryComponent
            summary={list.previousLineItemSummary}
            type="previous"
          />
        )}
        {list.inactivatedLineItemSummary.lineItems.length > 0 && (
          <LineItemSummaryComponent
            summary={list.inactivatedLineItemSummary}
            type="inactivated"
          />
        )} */}
        </ul>
      </section>
      <section className="flex flex-col gap-8">
        <h3 className="mx-auto">Villkor:</h3>
        <ul className="col-span-2 flex list-disc flex-col gap-1 pl-4">
          {props.list.userReadableConfirmation.terms.map((term) => (
            <li key={term}>{term}</li>
          ))}
        </ul>
      </section>

      <ReviewListOverviewFooter
        list={list}
        onSign={props.onSign}
        isSigning={props.isSigning}
      />
    </motion.section>
  );
};

interface LineItemListProps {
  lineItems: WorkOrderLineItem.CustomerType[];
}

const OverviewLineItemList = (props: LineItemListProps) => {
  const lineItems: WorkOrderLineItem.CustomerType[] = structuredClone(
    props.lineItems
  );

  lineItems.sort((a, b) => {
    // Prioritize active items over inactive ones
    if (a.isInactive !== b.isInactive) {
      return a.isInactive ? 1 : -1;
    }

    // If both items are active, sort by createdDate
    return a.createdDate < b.createdDate ? -1 : 1;
  });

  return (
    <ul className="flex flex-col gap-2 ">
      {lineItems.map((item) => {
        const price =
          item.customerPriceDetails.unitPriceIncludingVatWithoutReduction ?? 0;

        const rot = item.customerPriceDetails.taxReductionType === "rot";
        const green = item.customerPriceDetails.taxReductionType === "green";
        const isInactive = item.isInactive;

        return (
          <li
            key={item.id}
            className={ClientUtils.classNames(
              "flex flex-col rounded bg-slate-100 p-2",
              isInactive && "opacity-90"
            )}
          >
            <h4
              className={ClientUtils.classNames(
                "font-normal",
                isInactive && "italic"
              )}
            >
              {item.name}
            </h4>
            <PriceCalculationComponent
              quantity={item.quantity}
              price={price}
              preventBoldTotal
              useVerbosePricing
              unit={item.unit}
            />
            {!isInactive && (
              <>
                {rot && (
                  <span className="text-green-700">
                    Giltig för eventuellt ROT-avdrag
                  </span>
                )}
                {green && (
                  <span className="text-green-700">
                    Giltig för eventuellt avdrag för grön teknik
                  </span>
                )}
              </>
            )}
            {isInactive && (
              <p>Orderraden är inaktiverad och räknas inte mot totalen</p>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default OrderLineReviewListOverview;
