import React, { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useBrand } from "../../hooks/brand-hooks";
import AppRoutes from "../../routes";
import { GlobalContext } from "../../top-level-contexts";
import AppCompanyIcon from "../icons/AppCompanyIcon";
import AppEyeIcon from "../icons/AppEyeIcon";
import AppMailIcon from "../icons/AppMailIcon";
import AppPhoneIcon from "../icons/AppPhoneIcon";
import AppUserIcon from "../icons/AppUserIcon";
import MyLink, { MyLinkProps } from "../routing/MyLink";
import ClientUtils from "./../../utils/ClientUtils";
import AppExternalLinkIcon from "../icons/AppExternalLinkIcon";

interface Props {
  className?: string;
  omitProfile?: boolean;
}

const AppNavLinks = (props: Props) => {
  const { signInState, location } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();

  const brand = useBrand();

  let phone: string;
  let formattedPhone: string;
  let mail: string | undefined;

  const showContactInformation =
    signInState.signedInAs === "customer" || !signInState.isSignedIn;

  if (brand === "Svenska Eljouren") {
    phone = "0102881240";
    formattedPhone = "010-288 12 40";
    mail = "kontakt@svenskaeljouren.se";
  } else if (brand === "Rörjour 247") {
    phone = "0108885240";
    formattedPhone = "010-888 52 40";
    mail = "kontakt@rorjour247.se";
  } else if (brand === "Rörmokare.nu") {
    phone = "0108989830";
    formattedPhone = "010-89 89 830";
    mail = "kontakt@rörmokare.nu";
  } else {
    phone = "0108989046";
    formattedPhone = "010-898 90 46";
  }

  const iconSize = 24;
  const iconClass = "rounded-full";
  const textClass = "truncate";

  function onPage(pageWithoutEndingSlash: string) {
    const { pathname } = location;
    return (
      pathname === pageWithoutEndingSlash ||
      pathname === pageWithoutEndingSlash + "/"
    );
  }

  const onHomePage = onPage("/partner") && !searchParams.has("id");
  const onProfilePage = onPage("/partner/profil");
  const onCompanyPage = onPage("/partner/foretag");

  const showShopLink = !!signInState.handyman;

  return (
    <ul
      className={ClientUtils.twClassNames(
        "mx-auto flex h-full w-full flex-col gap-2 text-lg text-white lg:gap-8 lg:text-xl",
        props.className
      )}
    >
      <li className="mr-auto flex w-full">
        <ul className="flex w-full flex-col">
          {signInState.handyman && (
            <MenuItem>
              <StyledLink
                className="text-bold flex items-center gap-3 drop-shadow-sm"
                to={AppRoutes.partner.root()}
                onPage={onHomePage}
              >
                <AppEyeIcon size={iconSize} className={iconClass} />
                <span className={textClass}>Översikt</span>
              </StyledLink>
            </MenuItem>
          )}

          {signInState.handyman && !props.omitProfile && (
            <MenuItem>
              <StyledLink
                className="text-bold flex items-center gap-3 drop-shadow-sm"
                to={AppRoutes.partner.profile()}
                onPage={onProfilePage}
              >
                <AppUserIcon size={iconSize} className={iconClass} />
                <span className={textClass}>Profil</span>
              </StyledLink>
            </MenuItem>
          )}
          {signInState.handyman &&
            signInState.handyman.companyRole === "supervisor" && (
              <MenuItem>
                <StyledLink
                  onPage={onCompanyPage}
                  className="text-bold flex items-center gap-3 drop-shadow-sm"
                  to={AppRoutes.partner.company()}
                >
                  <AppCompanyIcon size={iconSize} className={iconClass} />
                  <span className={textClass}>Företag</span>
                </StyledLink>
              </MenuItem>
            )}
          {showShopLink && (
            <MenuItem>
              <a
                href={"https://shop.ipis.se/"}
                className={
                  "text-bold flex items-center gap-3 rounded p-3 text-lg drop-shadow-sm transition-all hover:bg-main-bg-dark"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <AppExternalLinkIcon size={20} />
                <span>Gå till IPIS webbshop</span>
              </a>
            </MenuItem>
          )}
        </ul>
      </li>

      {showContactInformation && (
        <li
          className="mr-auto mt-auto w-full p-2"
          //variants={contactSectionVariants}
        >
          <ul className="flex flex-col gap-4 rounded-md p-3 text-sm">
            <MenuItem>
              <a
                className="text-bold flex items-center gap-3 drop-shadow-sm"
                href={`tel:${phone}`}
              >
                <AppPhoneIcon size={20} className={iconClass} />
                <span className={textClass}>{formattedPhone}</span>
              </a>
            </MenuItem>
            {mail && (
              <MenuItem>
                <a
                  className="text-semibold flex items-center gap-3"
                  href={`mailto:${mail}`}
                >
                  <AppMailIcon size={20} className={iconClass} />
                  <span className={textClass}>{mail}</span>
                </a>
              </MenuItem>
            )}
          </ul>
        </li>
      )}
    </ul>
  );
};

const StyledLink = (
  props: {
    onPage: boolean;
  } & MyLinkProps &
    React.RefAttributes<HTMLAnchorElement>
) => {
  const { onPage, ...rest } = props;

  return (
    <MyLink
      {...rest}
      className={ClientUtils.twClassNames(
        "text-bold flex items-center gap-3 rounded p-3 text-lg drop-shadow-sm transition-all",
        onPage && "scale-10 pointer-events-none bg-main-bg-dark",
        !onPage && "hover:bg-main-bg-dark",
        props.className
      )}
    >
      {props.children}
    </MyLink>
  );
};

const MenuItem = (props: { children?: React.ReactNode }) => {
  return <li className="w-full">{props.children}</li>;
};

export default AppNavLinks;
