import React from "react";
import { Location } from "react-router-dom";
import IAuthRepo, { TSignInState } from "./_model/repos/interfaces/IAuthRepo";
import IBankIdRepo from "./_model/repos/interfaces/IBankIdRepo";
import IBookingsRepo from "./_model/repos/interfaces/IBookingsRepo";
import ICompanyRepo from "./_model/repos/interfaces/ICompanyRepo";
import IErrorLogRepo from "./_model/repos/interfaces/IErrorLogRepo";
import IHandymanRepo from "./_model/repos/interfaces/IHandymanRepo";
import IWorkOrderPaymentRepo from "./_model/repos/interfaces/IWorkOrderPaymentRepo";
import IWorkOrderRepo from "./_model/repos/interfaces/IWorkOrderRepo";
import ClientWorkOrderFileRepoV2 from "./_model/repos/ClientWorkOrderFileRepoV2";
import { IClientCustomerWorkOrderRepo } from "./_model/repos/interfaces/IClientCustomerWorkOrderRepo";
import HandymanWorkOrderApiClient from "./_model/api-clients/HandymanWorkOrderApiClient";
import PreparationFormApiClient from "./_model/api-clients/preparation-form/PreparationFormApiClient";
import WorkOrderLineItemApprovalRequestApiClient from "./_model/api-clients/WorkOrderLineItemApprovalRequestApiClient";

/*
	This might be worth to remove, once tests are in place.
*/
export const GlobalContext = React.createContext<{
  signInState: TSignInState;
  sessionActive: boolean;
  location: Location;
  isOnline: boolean;
}>({} as never);

export type ApiClientContextValues = {
  authRepo: IAuthRepo;
  companyRepo: ICompanyRepo;
  workOrderRepo: IWorkOrderRepo;
  handymanRepo: IHandymanRepo;
  errorLogRepo: IErrorLogRepo;
  bankIdRepo: IBankIdRepo;
  bookingsRepo: IBookingsRepo;
  workOrderPaymentRepo: IWorkOrderPaymentRepo;
  workOrderFileRepo: ClientWorkOrderFileRepoV2;
  ipisFormRepo: PreparationFormApiClient;
  customerWorkOrderRepo: IClientCustomerWorkOrderRepo;
  handymanWorkOrderApiClient: HandymanWorkOrderApiClient;
  workOrderLineItemApprovalRequestApiClient: WorkOrderLineItemApprovalRequestApiClient;
};

export const ApiClientContext = React.createContext<ApiClientContextValues>(
  {} as never
);
