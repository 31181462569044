import { WorkOrderLineItem, WorkOrderQuickAddLineItem } from "@eljouren/domain";
import React from "react";
import WorkOrderReimbursementCalculator from "../../../../_model/helpers/WorkOrderReimbursementCalculator";
import { UseMutableQueryResult } from "../../../../hooks/use-mutatable-query";
import { UseQueryWrapperResult } from "../../../../hooks/use-query-wrapper";

export type QuickAddSubmitValues = {
  lineItem: WorkOrderQuickAddLineItem.Type;
  quantity: number;
  type: "material" | "workingHour";
};

export type FilteredReturn = {
  type: "material" | "workingHour";
  lineItems: WorkOrderLineItem.HandymanType[];
  isLoading: boolean;
  isError: boolean;
};

export type WithApprovalStateReturn<
  T extends WorkOrderLineItem.HandymanType["approvalState"]
> = {
  state: T[];
  count: number;
  exists: boolean;
  items: WorkOrderLineItem.HandymanType[];
};

type HandymanLineItemContextType = {
  lineItemRes: UseMutableQueryResult<WorkOrderLineItem.HandymanType[]>;
  quickAddRes: UseQueryWrapperResult<WorkOrderQuickAddLineItem.Type[]>;
  //materialReviewListRes: UseMutableQueryResult<Awaited<ReturnType<IWorkOrderRepo["getMaterialReviewList"]>>>
  getMaterialLineItems: () => FilteredReturn;
  getWorkingHourLineItems: () => FilteredReturn;
  getMaterialQuickAddLineItems: () => WorkOrderQuickAddLineItem.Type[];
  getWorkingHourQuickAddLineItems: () => WorkOrderQuickAddLineItem.Type[];
  deleteOrInactivateLineItem: (
    args: WorkOrderLineItem.HandymanType
  ) => Promise<void>;
  updateLineItemQuantities: (args: {
    lineItems: WorkOrderLineItem.HandymanType[];
  }) => Promise<void>;
  onQuickAddProduct: (args: QuickAddSubmitValues) => Promise<void>;
  calculator: WorkOrderReimbursementCalculator;
  getWithApprovalState: <
    T extends WorkOrderLineItem.HandymanType["approvalState"]
  >(
    ...args: T[]
  ) => WithApprovalStateReturn<T>;
};
const HandymanLineItemContext =
  React.createContext<HandymanLineItemContextType>({} as never);

export default HandymanLineItemContext;
