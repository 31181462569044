import { motion } from "framer-motion";
import { useContext, useState } from "react";
import HandymanWorkOrderMarkAsFinishedModal from "../../../../routes/worker/order/HandymanWorkOrderMarkAsFinishedModal";
import WorkOrderFailedWorkOrderModal from "../../../../routes/worker/order/WorkOrderFailedWorkOrderModal";
import HandymanWorkOrderRouteContext from "../../../../routes/worker/order/contexts/HandymanWorkOrderRouteContext";
import InformationTooltip from "../../../InformationTooltip";
import { SignedInContext } from "../../../auth/hocs/withWorkerCredentials";
import MyDialog from "../../../common/MyDialog";
import { AppButton } from "../../../common/buttons/AppButton";
import AppCheckmarkIcon from "../../../icons/AppCheckmarkIcon";
import AppXIcon from "../../../icons/AppXIcon";
import HandymanLineItemContext from "../line-items/HandymanLineItemContext";
import ClientUtils from "./../../../../utils/ClientUtils";
import HandymanWorkOrderCheckInOutContext from "../../../../routes/worker/order/contexts/HandymanWorkOrderCheckInOutContext";

interface Props {
  className?: string;
}

const WorkOrderControlsFooter = (props: Props) => {
  const { handyman } = useContext(SignedInContext);
  const ctx = useContext(HandymanWorkOrderRouteContext);
  const lineItemCtx = useContext(HandymanLineItemContext);
  const checkInOutCtx = useContext(HandymanWorkOrderCheckInOutContext);

  const [markAsFinishedModalOpen, setMarkedAsFinishedModalOpen] =
    useState(false);
  const [markFailedWorkOrderModalOpen, setMarkFailedWorkOrderModalOpen] =
    useState(false);

  const [failedWorkOrderInfoModalOpen, setFailedWorkOrderInfoModalOpen] =
    useState(false);

  async function openMarkAsFinishedModal() {
    if (checkInOutCtx.isCheckingOut) {
      const confirmation = await window.modal.confirm({
        title: "Ej utcheckad",
        prompt:
          "Du är inte utcheckad från arbetsordern. Vill du checka ut eller avbryta klarmarkering?",
        yesLabel: "Checka ut och gå vidare",
        noLabel: "Avbryt och stanna kvar",
      });

      if (!confirmation) {
        return;
      }

      checkInOutCtx.onCheckInOut();
    }

    setMarkFailedWorkOrderModalOpen(false);
    setMarkedAsFinishedModalOpen(true);
  }
  function closeMarkAsFinishedModal() {
    setMarkedAsFinishedModalOpen(false);
  }

  function openMarkFailedWorkOrderModal() {
    setMarkedAsFinishedModalOpen(false);
    setMarkFailedWorkOrderModalOpen(true);
  }
  function closeMarkFailedWorkOrderModal() {
    setMarkFailedWorkOrderModalOpen(false);
  }

  function openFailedWorkOrderInfoModal() {
    setFailedWorkOrderInfoModalOpen(true);
  }

  function closeFailedWorkOrderInfoModal() {
    setFailedWorkOrderInfoModalOpen(false);
  }

  let checklistCompleted: boolean;
  let imagesCompleted: boolean;
  if (ctx.checklistRes.isLoading || !ctx.checklistRes.query.data) {
    /*
    If checklist is loading or not yet fetched, we assume it's completed to not show the "fill in all fields" message
    */
    checklistCompleted = true;
    imagesCompleted = true;
  } else {
    checklistCompleted =
      ctx.checklistRes.query.data.questions.allRequiredAnswered;
    imagesCompleted = ctx.checklistRes.query.data.images.allRequiredAnswered;
  }

  const actionSpecified = !!ctx.order.actionDescription;

  const allLineItemsApproved = lineItemCtx.lineItemRes.query.data?.every(
    (li) => li.approvalState !== "notApproved" && li.approvalState !== "pending"
  );

  const requirementsMet =
    checklistCompleted &&
    actionSpecified &&
    imagesCompleted &&
    allLineItemsApproved;

  return (
    <>
      <footer
        className={ClientUtils.twClassNames(
          "flex w-full flex-col gap-4 p-2 sm:m-4 sm:w-[calc(100%-2rem)] sm:rounded sm:border sm:bg-off-white/60 sm:p-4",
          props.className
        )}
      >
        {handyman.permittedToViewPrices && (
          <section className="flex flex-col pb-1">
            <h3 className="text-sm font-semibold ">
              Total ersättning för arbetet:
            </h3>
            <p className="text-lg font-bold">
              {lineItemCtx.calculator.forAll}:-
            </p>
          </section>
        )}

        {!ctx.order.isFinished && (
          <>
            <span className="grid w-full grid-cols-[auto,minmax(0,1fr)] items-center gap-2">
              <InformationTooltip
                id="mark-as-finished-tooltip"
                content={() => {
                  return (
                    <section className="z-50 flex w-64 flex-col gap-2">
                      <h3 className="text-sm font-semibold ">
                        Använd denna knapp när arbetet är helt eller delvis
                        genomfört
                      </h3>
                      <p className="text-sm ">
                        Om något återstår att göra så framgår det genom dina
                        svar på checklistan. Innan du klarmarkerar måste du ha:
                      </p>
                      <ul className="flex list-disc flex-col gap-2 pl-4">
                        <li className="text-sm">
                          <div className="grid grid-cols-[minmax(0,1fr),auto] grid-rows-1">
                            <span>specificerat åtgärd</span>
                            {actionSpecified ? (
                              <AppCheckmarkIcon className="text-green-600" />
                            ) : (
                              <AppXIcon className="text-red-600" />
                            )}
                          </div>
                        </li>
                        <li className="items-center gap-2 text-sm">
                          <div className="grid grid-cols-[minmax(0,1fr),auto] grid-rows-1">
                            <span>
                              fyllt i alla obligatoriska fält i checklistan
                            </span>
                            {checklistCompleted ? (
                              <AppCheckmarkIcon className="text-green-600" />
                            ) : (
                              <AppXIcon className="text-red-600" />
                            )}
                          </div>
                        </li>
                        <li className="text-sm">
                          <div className="grid grid-cols-[minmax(0,1fr),auto] grid-rows-1">
                            <span>laddat upp alla obligatoriska bilder</span>
                            {imagesCompleted ? (
                              <AppCheckmarkIcon className="text-green-600" />
                            ) : (
                              <AppXIcon className="text-red-600" />
                            )}
                          </div>
                        </li>
                        {ctx.enableMaterialReview && (
                          <li className="text-sm">
                            <div className="grid grid-cols-[minmax(0,1fr),auto] grid-rows-1">
                              <span>fått alla orderrader godkända</span>
                              {allLineItemsApproved ? (
                                <AppCheckmarkIcon className="text-green-600" />
                              ) : (
                                <AppXIcon className="text-red-600" />
                              )}
                            </div>
                          </li>
                        )}
                      </ul>
                    </section>
                  );
                }}
                open={{
                  default: "top-right",
                  md: "top-right",
                }}
                iconProps={{
                  size: 24,
                }}
              />
              <AppButton
                aria-describedby="mark-as-finished-tooltip"
                requiresNetworkConnection={false}
                onClick={openMarkAsFinishedModal}
                disabled={
                  ctx.orderRes.isLoading ||
                  ctx.order.isFinished ||
                  !requirementsMet
                }
                loading={ctx.checklistRes.isLoading}
              >
                Klarmarkera arbete
              </AppButton>
            </span>
            {ctx.enableFailedWorkOrderReporting && (
              <span className="grid w-full grid-cols-[auto,minmax(0,1fr)] items-center gap-2">
                <InformationTooltip
                  id="mark-as-finished-tooltip"
                  content={() => {
                    return (
                      <section className="flex w-64 flex-col gap-2">
                        <h3 className="text-sm font-semibold ">
                          Använd denna knapp när arbetet inte gick att utföra,
                          t.ex. vid en bomkörning.
                        </h3>
                        <p className="text-sm ">
                          Du kommer kunna välja alternativ för varför arbetet
                          inte gick att utföra i nästa steg, alternativt skriva
                          en egen förklaring.
                        </p>
                      </section>
                    );
                  }}
                  open={{
                    default: "top-right",
                    md: "top-right",
                  }}
                  iconProps={{
                    size: 24,
                  }}
                />

                <AppButton
                  requiresNetworkConnection={false}
                  onClick={openMarkFailedWorkOrderModal}
                  variant="outline-error"
                  disabled={ctx.order.isFinished}
                  loading={ctx.checklistRes.isLoading}
                >
                  Arbetet gick ej att utföra
                </AppButton>
              </span>
            )}
          </>
        )}
        {!ctx.order.isFinished &&
          !!ctx.order.serviceContract &&
          !ctx.enableFailedWorkOrderReporting && (
            <AppButton
              requiresNetworkConnection={false}
              onClick={openFailedWorkOrderInfoModal}
              variant="outline-error"
              loading={ctx.checklistRes.isLoading}
            >
              Arbetet gick ej att utföra
            </AppButton>
          )}
      </footer>
      <MyDialog
        isOpen={markAsFinishedModalOpen}
        cancel={closeMarkAsFinishedModal}
      >
        <HandymanWorkOrderMarkAsFinishedModal
          onClose={closeMarkAsFinishedModal}
        />
      </MyDialog>
      {ctx.enableFailedWorkOrderReporting && (
        <MyDialog
          isOpen={markFailedWorkOrderModalOpen}
          cancel={closeMarkFailedWorkOrderModal}
        >
          <WorkOrderFailedWorkOrderModal
            onClose={closeMarkFailedWorkOrderModal}
          />
        </MyDialog>
      )}

      {!ctx.enableFailedWorkOrderReporting && (
        <MyDialog isOpen={failedWorkOrderInfoModalOpen}>
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={ClientUtils.twClassNames(
              props.className,
              "flex h-full w-full flex-col bg-white/90 p-4 backdrop-blur md:pt-32"
            )}
          >
            <div className="mx-auto flex max-w-screen-md flex-col gap-4">
              <header className="text-lg font-bold">
                Rutin för ett arbete som inte gick att genomföra
              </header>
              <main className="flex flex-col gap-4">
                <p>
                  Om uppdraget resulterar i en bomkörning eller om
                  installationen inte kan genomföras, är det viktigt att du
                  kontaktar Avtalssupporten på{" "}
                  <a
                    href="tel:+46102881249"
                    className="font-bold text-blue-600"
                  >
                    +46102881249
                  </a>{" "}
                  och uppger orsaken så att uppdraget kan klarmarkeras.
                </p>
                <p>
                  Inom kort kommer funktionalitet för automatisk hantering av
                  detta att vara på plats.
                </p>
              </main>
              <footer className="flex flex-col items-end pt-8">
                <AppButton onClick={closeFailedWorkOrderInfoModal} className="">
                  Jag förstår
                </AppButton>
              </footer>
            </div>
          </motion.section>
        </MyDialog>
      )}
    </>
  );
};

export default WorkOrderControlsFooter;
