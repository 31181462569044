import { UploadFilesOutput } from "@eljouren/file-schemas";
import { CustomFileInputFile } from "../../components/files/FileInputButton";
import trpcClient from "../../trpc-setup";
import ClientWorkOrderFileRepoV2 from "../repos/ClientWorkOrderFileRepoV2";
import { WorkOrderFailedReport } from "@eljouren/domain";
import { FileUploadNewFile } from "../../hooks/file-upload-hooks";

/* 
	Not sure if this taking another api client (or repo, like the old and incorrect terminology),
	is the correct way to go about this.
*/
export default class HandymanWorkOrderApiClient {
  constructor(
    private props: {
      workOrderFileServerApiClient: ClientWorkOrderFileRepoV2;
    }
  ) {}

  /* 
 	Could probably use a better name 

	When/if we've created a serverside method for reverting the answer to the question,
	we should call that in case the image uploading fails.
  */
  async submitCompletionFormImageQuestionAnswer(args: {
    workOrderId: string;
    questionId: string;
    comment: string | null;
    images: CustomFileInputFile[];
  }): Promise<UploadFilesOutput.Type> {
    const res =
      await trpcClient.handymanWorkOrderCompletionFormRouter.submitImageAnswer.mutate(
        {
          workOrderId: args.workOrderId,
          questionId: args.questionId,
          comment: args.comment,
        }
      );

    const presignedUrlResponse = res.presignedUrlResponse;

    const images =
      await this.props.workOrderFileServerApiClient.uploadWithPresignedUrl({
        url: presignedUrlResponse.url,
        files: args.images,
      });

    return images;
  }

  async reportAsUnsuccessful(args: WorkOrderFailedReport.Type): Promise<void> {
    await trpcClient.v2HandymanWorkOrderRouter.reportAsUnsuccessful.mutate({
      workOrderId: args.workOrderId,
      reason: args.reason,
      comment: args.comment,
    });
  }

  /* 
    Could/should validate here that we only have images, and not other types of files.
  */
  async uploadUnsuccessfulWorkOrderImages(args: {
    workOrderId: string;
    images: FileUploadNewFile[];
  }): Promise<
    | { success: true; output: UploadFilesOutput.Type }
    | {
        success: false;
        reason:
          | "FAILED_TO_GENERATE_PRESIGNED_URL"
          | "FAILED_TO_UPLOAD_ALL_FILES"
          | "FAILED_TO_UPLOAD_SOME_FILES";
        output?: UploadFilesOutput.Type;
      }
  > {
    try {
      const res =
        await trpcClient.v2HandymanWorkOrderRouter.generateUnsuccessfulWorkOrderPresignedUploadUrl.mutate(
          {
            workOrderId: args.workOrderId,
          }
        );

      if (!res.success) {
        return res;
      }

      const maybeOutput = await this.tryUploadFailedWorkOrderImages({
        url: res.presignedUrl.url,
        workOrderId: args.workOrderId,
        files: args.images,
      });

      if (!maybeOutput) {
        return {
          success: false,
          reason: "FAILED_TO_UPLOAD_ALL_FILES",
        };
      }

      const hasUploadedFiles = maybeOutput.uploadedFiles.length > 0;

      if (!hasUploadedFiles) {
        return {
          success: false,
          reason: "FAILED_TO_UPLOAD_ALL_FILES",
        };
      }

      const hasErrors = maybeOutput.rejectedFiles.length > 0;

      if (hasErrors) {
        return {
          success: false,
          reason: "FAILED_TO_UPLOAD_SOME_FILES",
        };
      }

      return {
        success: true,
        output: maybeOutput,
      };
    } catch (er) {
      return {
        success: false,
        reason: "FAILED_TO_GENERATE_PRESIGNED_URL",
      };
    }
  }

  private async tryUploadFailedWorkOrderImages(args: {
    url: string;
    workOrderId: string;
    files: FileUploadNewFile[];
  }): Promise<UploadFilesOutput.Type | null> {
    try {
      const fileRes =
        await this.props.workOrderFileServerApiClient.uploadWithPresignedUrl({
          url: args.url,
          files: args.files.map((file) => {
            return {
              ...file,
              meta: {
                ...file.meta,
                name: "Underlag för bomkörning",
              },
            };
          }),
        });
      return fileRes;
    } catch (er) {
      await trpcClient.v2HandymanWorkOrderRouter.logFailedWorkOrderImageUploading.mutate(
        {
          workOrderId: args.workOrderId,
        }
      );
      return null;
    }
  }
}
