"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialReviewList = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderLineItem_1 = require("./WorkOrderLineItem");
/*
  Should be renamed to WorkOrderLineItemApprovalRequest, and the new code created that handles
  this object will refer to it as such. But actually refactoring the name will be for another day.
*/
var MaterialReviewList;
(function (MaterialReviewList) {
    MaterialReviewList.CustomerLineItemSummary = centralized_zod_1.z.object({
        lineItems: WorkOrderLineItem_1.WorkOrderLineItem.CustomerSchema.array(),
        totalPriceWithoutTaxReduction: centralized_zod_1.z.number(),
        totalPriceWithTaxReduction: centralized_zod_1.z.number(),
    });
    MaterialReviewList.HandymanLineItemSummary = centralized_zod_1.z.object({
        lineItems: WorkOrderLineItem_1.WorkOrderLineItem.HandymanSchema.array(),
        totalPriceWithoutTaxReduction: centralized_zod_1.z.number(),
        totalPriceWithTaxReduction: centralized_zod_1.z.number(),
    });
    var BaseSchema = centralized_zod_1.z.object({
        hash: centralized_zod_1.z.string(),
        workOrderId: centralized_zod_1.z.string(),
        createdDate: centralized_zod_1.z.date(),
        taxReductionType: centralized_zod_1.z.enum(["rot", "green", "rut"]).nullable(),
        /*
          This field is no longer used. See comment in WorkOrderLineItemApprovalRequestService for details.
        */
        //treatingNotApprovedAsPending: z.boolean(),
        totalPriceWithoutTaxReduction: centralized_zod_1.z.number(),
        totalPriceWithTaxReduction: centralized_zod_1.z.number(),
        taxReductionAmount: centralized_zod_1.z.number(),
        userReadableConfirmation: centralized_zod_1.z.object({
            text: centralized_zod_1.z.string(),
            terms: centralized_zod_1.z.string().array().min(1),
        }),
    });
    MaterialReviewList.HandymanSchema = BaseSchema.extend({
        viewedBy: centralized_zod_1.z.literal("handyman"),
        /*
          The line items that aren't approved and haven't been sent for approval yet.
          This is a new field which simplifies the handyman flow. It might be worth considering
          hiding this field for customers.
        */
        notApprovedLineItemSummary: MaterialReviewList.HandymanLineItemSummary,
        /*
            The line items that are pending approval
          */
        pendingLineItemSummary: MaterialReviewList.HandymanLineItemSummary,
        previouslyApprovedLineItemSummary: MaterialReviewList.HandymanLineItemSummary,
        /*
            The line items that have been approved and then deleted. In this case,
            we don't actually delete them, but mark them as inactive instead.
          */
        inactivatedLineItemSummary: MaterialReviewList.HandymanLineItemSummary,
    });
    MaterialReviewList.CustomerSchema = BaseSchema.extend({
        viewedBy: centralized_zod_1.z.literal("customer").optional(),
        /*
          Hide this for customers
        */
        //notApprovedLineItemSummary: CustomerLineItemSummary,
        /*
            The line items that are pending approval
          */
        pendingLineItemSummary: MaterialReviewList.CustomerLineItemSummary,
        previouslyApprovedLineItemSummary: MaterialReviewList.CustomerLineItemSummary,
        /*
            The line items that have been approved and then deleted. In this case,
            we don't actually delete them, but mark them as inactive instead.
          */
        inactivatedLineItemSummary: MaterialReviewList.CustomerLineItemSummary,
    });
    MaterialReviewList.Schema = centralized_zod_1.z.union([MaterialReviewList.HandymanSchema, MaterialReviewList.CustomerSchema]);
})(MaterialReviewList || (MaterialReviewList = {}));
exports.MaterialReviewList = MaterialReviewList;
