import { MaterialReviewList } from "@eljouren/domain";
import { CustomFileInputFile } from "../../components/files/FileInputButton";
import trpcClient from "../../trpc-setup";

export default class WorkOrderLineItemApprovalRequestApiClient {
  async sendApprovalRequest(args: {
    workOrderId: string;
    phoneNumber: string;
  }) {
    return trpcClient.workOrderLineItemApprovalRequestRouter.sendApprovalRequest.mutate(
      args
    );
  }

  async resendTextToCustomer(args: {
    workOrderId: string;
    phoneNumber: string;
  }) {
    return trpcClient.workOrderLineItemApprovalRequestRouter.resendTextToCustomer.mutate(
      args
    );
  }

  async canResendTextToCustomer(args: {
    workOrderId: string;
    phoneNumber: string;
  }) {
    return trpcClient.workOrderLineItemApprovalRequestRouter.canResendTextToCustomer.query(
      args
    );
  }

  async fetchApprovalRequestForCustomer() {
    return trpcClient.workOrderLineItemApprovalRequestRouter.fetchApprovalRequestForCustomer.query();
  }

  async fetchApprovalRequestForHandyman(args: { workOrderId: string }) {
    return trpcClient.workOrderLineItemApprovalRequestRouter.fetchApprovalRequestForHandyman.query(
      args
    );
  }

  private verifyOnlyImages(images: CustomFileInputFile[]): void {
    const allFilesAreImages = images.every((file) => {
      const type = file.native.type;
      if (!type) {
        /* 
          Heic images has an empty type, so we'll let the 
          server validate this
        */
        return true;
      }
      return type.startsWith("image/");
    });

    /* 
      This should also be validated on the server,
      but I will settle for client-side validation until
      the fileserver is rewritten
    */
    if (!allFilesAreImages) {
      throw new Error("All files must be images");
    }
  }

  /* 
 	This needs error handling, but the issues is not mostly with this method in itself
	but rather with the flow as a whole, which is documented in the server.
	
	Until the flow has been refactored, I'm not gonna spend time on error handling here.
  */
  async approveViaPicture(args: {
    workOrderId: string;
    images: CustomFileInputFile[];
    requestFromHandymanPerspective: MaterialReviewList.HandymanType;
  }) {
    this.verifyOnlyImages(args.images);
    const promises = args.images.map((image) => {
      return trpcClient.workOrderLineItemApprovalRequestRouter.approveViaPicture.mutate(
        {
          workOrderId: args.workOrderId,
          dataUrl: image.dataUrl,
          requestFromHandymanPerspective: args.requestFromHandymanPerspective,
          description: image.meta.description ?? null,
        }
      );
    });
    await Promise.all(promises);
  }

  async initiateBankIdSigning(args: { hash: string; testMode?: boolean }) {
    if (process.env.NODE_ENV !== "development" && args.testMode) {
      throw new Error("Testmode only permitted in development.");
    }
    return trpcClient.workOrderLineItemApprovalRequestRouter.initiateBankIdSigning.mutate(
      args
    );
  }

  /* 
 	Legacy, only used for testing. 
  */
  async setRequiresMaterialToBeReviewed(args: {
    workOrderId: string;
    value: boolean;
  }): Promise<void> {
    await trpcClient.workOrderLineItem.setRequiresMaterialToBeReviewed.mutate(
      args
    );
  }
}
